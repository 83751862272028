import style from './index.module.scss'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import axios from '../../../util/axios'
import { Toast, Picker, Input } from 'antd-mobile'

function AfterSales() {
  const location = useLocation()
  const info = location.state
  let [state, setState] = useState({
    id: info.id,
    tracking_company: '',
    tracking_no: '',
    tracking_phone: info.shipper_info.contact_number
  })

  let [visibleExpress, setVisibleExpress] = useState(false)

  let [expressList, setExpressList] = useState([])
  let [expressValue, setExpressValue] = useState([])
  const getExpressList = () => {
    axios.post('/JuTwitter/Orderrefund/expressList').then((res) => {
      if (res.code == 0) {
        let { list } = res.result
        let arr = list.map((item) => {
          return {
            label: item.company_name,
            value: item.company_name
          }
        })
        setExpressList([arr])
      }
    })
  }
  useEffect(() => {
    getExpressList()
  }, [])
  return (
    <div className={style.index}>
      <div className={style.content}>
        <div className={style.left}>收件人</div>
        <div>{info.shipper_info.name}</div>
      </div>
      <div className={style.content}>
        <div className={style.left}>手机号</div>
        <div>{info.shipper_info.contact_number}</div>
      </div>
      <div className={style.content}>
        <div className={style.left}>收货地址</div>
        <div className={style.right}>
          <div>{info.shipper_info.combine_detail}</div>
          <div>{info.shipper_info.address}</div>
        </div>
      </div>
      <div className={style.content}>
        <div className={style.left}>
          物流公司<span>*</span>
        </div>
        <div
          onClick={() => {
            setVisibleExpress(true)
          }}
        >
          {state.tracking_company || '请选择物流公司'}
          <img className={style.logisticsIcon} src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/rightArrow_2.png" />
        </div>
        <Picker
          columns={expressList}
          visible={visibleExpress}
          onClose={() => {
            setVisibleExpress(false)
          }}
          value={expressValue}
          onConfirm={(v) => {
            setExpressValue(v)
            setState({
              ...state,
              tracking_company: v[0]
            })
          }}
        />
      </div>
      <div className={style.content}>
        <div className={style.left}>
          物流单号<span>*</span>
        </div>
        <Input
          className={style.input}
          type="number"
          value={state.tracking_no}
          placeholder="请填写正确的物流单号"
          onChange={(val) => {
            setState({
              ...state,
              tracking_no: val
            })
          }}
        />
      </div>
      <div
        className={style.footer}
        onClick={() => {
          let data = { ...state }

          if (!data.tracking_company) {
            return Toast.show('请选择物流公司')
          }
          if (!data.tracking_no) {
            return Toast.show('请输入物流单号')
          }
          axios.post('/JuTwitter/Orderrefund/setTrackingNo', data).then((res) => {
            if (res.code == 0) {
              Toast.show('提交成功')
              setTimeout(() => {
                window.history.go(-1)
              }, 2000)
            }
          })
        }}
      >
        确认
      </div>
    </div>
  )
}

export default AfterSales
