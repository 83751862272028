import "./index.scss";
import React, { useState, useEffect, useRef } from 'react';
import axios from "../../util/axios";
import { useNavigate, useLocation } from 'react-router-dom';
import { Swiper, Image, Toast, TextArea } from "antd-mobile";
import { getDateformatText } from "../../util/utils";
import _ from "lodash";
function CommunityDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    let [info, setInfo] = useState({
        content_data: []
    })
    let [showHand, setShowHand] = useState(!1);
    let videoRef = useRef(null);
    let [isPlay, setIsPlay] = useState(!0);
    let getInfo = () => {
        axios.post("/JuTwitter/ForumsPosts/info", {
            id: location.state
        }).then(res => {
            if (res.code == 0) {
                setInfo(res.result);
                if (res.result.content_type == 2) {
                    setTimeout(() => {
                        videoRef.current.play();
                    }, 1000)
                }
            }
        })
    }
    let follow = () => {
        axios.post("/JuTwitter/ForumsPosts/collectWechatUser", {
            id: info.user_id,
            status: info.is_follow ? 0 : 1
        }).then(res => {
            if (res.code == 0) {
                let _info = _.cloneDeep(info);
                _info.is_follow = info.is_follow ? 0 : 1;
                Toast.show({
                    content: _info.is_follow ? '已关注' : '已取消'
                })
                setInfo(_info);

            } else {
                Toast.show({
                    content: res.msg
                })
            }
        })
    }
    let like = () => {
        if (!info.is_like) {
            axios.post("/JuTwitter/ForumsPosts/postlike", {
                id: info.id
            }).then(res => {
                if (res.code == 0) {
                    let _info = _.cloneDeep(info);
                    _info.is_like = 1;
                    _info.like_num++;
                    setInfo(_info);
                } else {
                    Toast.show({
                        content: res.msg
                    })
                }
            })
        }
    }
    let collect = () => {
        axios.post("/JuTwitter/ForumsPosts/collect", {
            id: info.id,
            status: info.is_collect ? 0 : 1
        }).then(res => {
            if (res.code == 0) {
                let _info = _.cloneDeep(info);
                _info.is_collect = info.is_collect ? 0 : 1;
                setInfo(_info);
            } else {
                Toast.show({
                    content: res.msg
                })
            }
        })
    }
    useEffect(() => {
        document.title = "种草社区";
        getInfo();
    }, [])
    return (
        <div className="community-details">
            <div className="name">
                <div className="left">
                    <img src={info.avater} onClick={() => navigate("/publishingCenter", { state: { openid: info.openid, is_follow: info.is_follow } })} />
                    <div>
                        <p>{info.nick_name}</p>
                        <p>{getDateformatText(info.create_time)}</p>
                    </div>
                </div>
                {
                    !info.is_self ? <div className={`right ${info.is_follow ? 'right-active' : ''}`} onClick={() => follow()}>{info.is_follow ? '已关注' : '关注'}</div> : null
                }
            </div>
            {
                info.content_type == 3 ? <div className="img">
                    <Swiper loop>
                        {
                            info.content_data.map((item, index) => {
                                return (
                                    <Swiper.Item key={index}>
                                        <Image src={item} fit="cover"></Image>
                                    </Swiper.Item>
                                )
                            })
                        }
                    </Swiper>
                </div> : <div className="video">
                    {
                        !isPlay ? <img src={require("../../image/community/icon11.png")} onClick={() => {
                            videoRef.current.play();
                            setIsPlay(!0);
                        }} /> : null
                    }
                    <video ref={videoRef} src={info.content_data} loop webkit-playsinline="true" playsinline="true" onClick={() => {
                        videoRef.current.pause();
                        setIsPlay(!1);
                    }}></video>
                </div>
            }
            <div className="title">{info.title}</div>
            <div className="content">
                <TextArea
                    style={{'--disabled-color':'#333'}}
                    autoSize={true}
                    disabled
                    value={info.content}
                />
            </div>
            <div className="like-collect-share">
                <img className="share" src={require("../../image/community/icon8.png")} onClick={() => {
                    setShowHand(!0);
                    setTimeout(() => {
                        setShowHand(!1);
                    }, 8000)
                }} />
                <div className="like">
                    <img src={info.is_like ? require("../../image/community/icon10.png") : require("../../image/community/icon9.png")} onClick={() => like()} />
                    <span>{info.like_num >= 1000 ? parseFloat((info.like_num / 1000).toFixed(2)) + 'k' : info.like_num}</span>
                </div>
                <img className="collect" src={info.is_collect ? require("../../image/community/icon12.png") : require("../../image/community/icon13.png")} onClick={() => collect()} />
            </div>
            {
                showHand ? <img className="hand" src={require("../../image/community/icon14.png")} alt="" /> : null
            }
        </div>
    )
}

export default CommunityDetails;