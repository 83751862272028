import './index.scss'
import React, { useState, useEffect } from 'react'
import axios from '../../util/axios'
import { useNavigate, useLocation } from 'react-router-dom'
import _ from 'lodash'
import { uniacid } from '../../util/config'
function MyAddress () {
    const navigate = useNavigate()
    const location = useLocation()
    let [list, setList] = useState([])
    let [state, setState] = useState({
        page: 1
    })
    let is_getList = !0
    let getList = () => {
        axios
            .post('/JuTwitter/Address/list', {
                uniacid: uniacid,
                rows: 10,
                page: state.page
            })
            .then((res) => {
                if (res.code == 0) {
                    let data = res.result.list
                    let lists = _.cloneDeep(list)
                    if (data.length) {
                        lists.push(...data)
                        setList(lists)
                        setState({
                            page: state.page + 1
                        })
                    }
                }
            })
    }
    useEffect(() => {
        document.title = '我的地址'
        getList()
    }, [])
    return (
        <div
            className="myAddress"
            onScroll={(e) => {
                let { clientHeight, scrollHeight, scrollTop } = e.target
                if (scrollTop + clientHeight + 20 > scrollHeight) {
                    if (is_getList) {
                        is_getList = !1
                        getList()
                    }
                }
            }}
        >
            {list.map((item, index) => {
                return (
                    <div
                        className="content"
                        key={index}
                        onClick={() => {
                            if (location.state) {
                                axios
                                    .post('/JuTwitter/Address/editDefaultAddress', {
                                        uniacid: uniacid,
                                        id: item.id
                                    })
                                    .then((res) => {
                                        if (res.code == 0) {
                                            navigate(-1)
                                        }
                                    })
                            } else {
                                navigate('/addAddress', { state: item.id })
                            }
                        }}
                    >
                        <div>
                            <div className="addressTop">
                                <div className="name">{item.name}</div>
                                <div className="phone">{item.mobile}</div>
                                {item.is_default ? <div className="default">默认</div> : null}
                            </div>
                            <div className="detailedAddress">{item.full_name_address}</div>
                        </div>
                        <img className="editAddress" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/editAddress.png" />
                    </div>
                )
            })}
            <div className="footer" onClick={() => navigate('/addAddress')}>
                新建地址
            </div>
            {/* <div className="empty">
                <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/empty_address.png" className="empty_img" />
                <div className="noStyle">您还没有收货地址</div>
                <div className="footer2"> 新建地址</div >
            </div > */}
        </div>
    )
}

export default MyAddress
