import style from "./index.module.scss";
import React, { useState, useEffect } from 'react';
import axios from "../../util/axios";
import { getDateformat } from "../../util/utils";
import Empty from "../../components/empty";
import _ from "lodash";
let is_getList = !0;
function WithdrawalLog() {
    let [list, setList] = useState([]);
    let [state, setState] = useState({
        status: '',
        page: 1,
        type: [{
            name: '全部',
            status: ''
        }, {
            name: '提现中',
            status: 1
        }, {
            name: '已打款',
            status: 2
        },
        {
            name: '已驳回',
            status: -1
        }]
    })
    let lists = [];
    let getList = () => {
        axios.post("/JuTwitter/User/withdrawLog", {
            status: state.status,
            page: state.page,
            rows: 10
        }).then(res => {
            if (res.code == 0) {
                is_getList = !0;
                let list = res.result.list;
                let data = _.cloneDeep(lists);
                if (list.length) {
                    lists.push(...list);
                    data.push(...list);
                    setList(data);
                    setState({
                        ...state,
                        page: state.page++
                    })
                } else {
                    is_getList = !1
                }
            }
        }).catch(() => {
            is_getList = !0;
        })
    }
    let selectType = (status) => {
        if (status != state.status) {
            setList([]);
            is_getList = !0;
            console.log(is_getList)
            setState({
                ...state,
                page: 1,
                status: status
            })
        }
    }
    let handelPageScroll = () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + windowHeight >= scrollHeight) {
            if (is_getList) {
                is_getList = !1;
                setTimeout(() => {
                    getList();
                }, 400);
            }
        }
    }
    useEffect(() => {
        getList();
    }, [state.status])
    useEffect(() => {
        document.title = "提现记录";
        window.addEventListener("scroll", handelPageScroll, false);
        return (() => {
            window.removeEventListener("scroll", handelPageScroll);
        })
    }, [])
    return (
        <div className={style.content}>
            <div className={style.type}>
                {
                    state.type.map((item, index) => {
                        return (
                            <div className={item.status === state.status ? style.active : null} key={index} onClick={() => selectType(item.status)}>{item.name}</div>
                        )
                    })
                }
            </div>
            <div className={style.list}>
                {
                    list.map((item, index) => {
                        return (
                            <div className={style.item} key={index}>
                                <div>
                                    <span>提现单号：{item.withdraw_no}</span>
                                    <span>-{item.withdrawal_amount}</span>
                                </div>
                                <div>
                                    <span>{getDateformat(item.create_time)}</span>
                                    <span style={{ color: item.status == - 1 ? '#FF4343' : '' }}>{item.status == 1 ? '提现中' : item.status == 2 ? '已打款' : '已驳回'}</span>
                                </div>
                                {
                                    item.status == -1 ? <div className={style.reject}>驳回原因：提现操作异常</div> : null
                                }
                            </div>
                        )
                    })
                }
            </div>
            {
                list.length === 0 ? <Empty text="暂无数据" marginTop={200 / 37.5 + 'rem'} /> : null
            }
        </div>
    )
}

export default WithdrawalLog