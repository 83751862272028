import style from "./index.module.scss";
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
function ActivationApply(){
    const navigate = useNavigate();
    useEffect(()=>{
        document.title = '微码惠';
    },[])
    return (
        <div className={style.back}  style={{ backgroundImage: "url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/img2.png)" }}>
            <div onClick={()=> navigate("/activation")}>立即申请</div>
        </div>
    )
}

export default ActivationApply;