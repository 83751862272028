import "./index.scss";
import React, { useState, useEffect } from 'react';
import { Input, Image, Swiper } from 'antd-mobile';
import axios from "../../../util/axios";
import City from "../../../components/city";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
function EnterpriseList() {
    const navigate = useNavigate();
    let [category, setCategory] = useState([]);
    let [banner, setBanner] = useState([]);
    let [visible, setVisible] = useState(false);
    let [state, setState] = useState({
        list: [],
        page: 1,
        shop_name: '',
        province_id: '',
        city_id: '',
        area_id: '',
        category_id: '',
        is_getList: !0,
        is_search: !1,
    })
    let getList = () => {
        axios.post("/JuTwitter/Company/list", {
            page: state.page,
            rows: 10,
            shop_name: state.shop_name,
            province_id: state.province_id,
            city_id: state.city_id,
            area_id: state.area_id,
            category_id: state.category_id
        }).then(res => {
            if (res.code == 0) {
                let _state = _.cloneDeep(state);
                let list = res.result.list;
                if (list.length) {
                    _state.page++;
                    _state.is_getList = !0;
                    _state.list.push(...list);
                }
                _state.is_search = !1;
                setState(_state);
            }
        })
    }
    useEffect(() => {
        state.is_search && getList();
    }, [state.is_search])
    useEffect(() => {
        !state.is_getList && getList();
    }, [state.is_getList])
    useEffect(() => {
        getList();
    }, [state.category_id])
    useEffect(() => {
        state.area_id && getList();
    }, [state.area_id])
    useEffect(() => {
        axios.post("/JuTwitter/CompanyAd/list").then(res => {
            if (res.code == 0) {
                setBanner(res.result);
            }
        })
        axios.post("/JuTwitter/Company/categoryList").then(res => {
            if (res.code == 0) {
                res.result.unshift({
                    name: '全部',
                    id: ''
                })
                setCategory(res.result);
            }
        })
    }, [])
    return (
        <div className="enterprise-list" onScroll={(e) => {
            let { clientHeight, scrollHeight, scrollTop } = e.target;
            if (scrollTop + clientHeight + 20 > scrollHeight) {
                if (state.is_getList) {
                    setState({
                        ...state,
                        is_getList: !1
                    })
                }
            }
        }}>
            <div className="enterprise-search">
                <div className="screen" onClick={() => setVisible(true)}>筛选</div>
                <City visible={visible} cancel={setVisible} confirm={({ ids, name }) => {
                    setVisible(false);
                    setState({
                        ...state,
                        list: [],
                        page: 1,
                        province_id: ids[0],
                        city_id: ids[1],
                        area_id: ids[2]
                    })
                }} type={2} ></City>
                <div className="put">
                    <Image src={require('../../../image/icon27.png')} />
                    <Input onChange={e => setState({ ...state, shop_name: e })} placeholder="请输入关键词" />
                </div>
                <div className="search-btn" onClick={() => {
                    setState({
                        ...state,
                        list: [],
                        page: 1,
                        is_search: !0
                    })
                }}>搜索</div>
                <div className="reset" onClick={() => {
                    setState({
                        ...state,
                        list: [],
                        page: 1,
                        shop_name: '',
                        province_id: '',
                        city_id: '',
                        area_id: '',
                        is_search: !0
                    })
                }}>重置</div>
            </div>
            <div className="enterprise-banner">
                {
                    banner.length > 1 ? <Swiper style={{
                        '--border-radius': '6px',
                    }} loop>
                        {
                            banner.map((item, index) => {
                                return (
                                    <Swiper.Item key={index}>
                                        <Image src={item.cover_picture} onClick={() => {
                                            if (item.goto_type == 2) navigate("/enterpriseGoodsDetails", { state: item.goto_id })
                                            if (item.goto_type == 1) navigate("/enterpriseShop", { state: item.goto_id })
                                        }} />
                                    </Swiper.Item>
                                )
                            })
                        }
                    </Swiper> : <Swiper style={{
                        '--border-radius': '6px',
                    }} indicator={() => null}>
                        {
                            banner.map((item, index) => {
                                return (
                                    <Swiper.Item key={index}>
                                        <Image src={item.cover_picture} onClick={() => {
                                            if (item.goto_type == 2) navigate("/enterpriseGoodsDetails", { state: item.goto_id })
                                            if (item.goto_type == 1) navigate("/enterpriseShop", { state: item.goto_id })
                                        }} />
                                    </Swiper.Item>
                                )
                            })
                        }
                    </Swiper>
                }
            </div>
            <div className="list-category">
                <div className="category">
                    {
                        category.map((item, index) => {
                            return <div className={`${state.category_id == item.id ? 'active' : ''}`} key={index} onClick={() => {
                                setState({
                                    ...state,
                                    page: 1,
                                    list: [],
                                    is_getList: 0,
                                    category_id: item.id
                                })
                            }}>{item.name}</div>
                        })
                    }
                </div>
                <div className="list">
                    {
                        state.list.map((item, index) => {
                            return (
                                <div className="item" key={index}>
                                    <div className="enterprise-info">
                                        <div className="logo-name">
                                            <img src={item.shop_logo} />
                                            <div className="name">
                                                <p>{item.shop_name}</p>
                                                {item.shop_Introduction ? <p>{item.shop_Introduction}</p> : null}
                                            </div>
                                        </div>
                                        <div className="go" onClick={() => navigate("/enterpriseShop", { state: item.id })}>进店</div>
                                    </div>
                                    {
                                        item.shop_banner ? <div className="banner">
                                            <img src={JSON.parse(item.shop_banner)[0]} />
                                        </div> : null
                                    }

                                    <div className="goods">
                                        {
                                            item.hot_goods.map((goods, y) => {
                                                return (
                                                    <div className="goods-item" style={{ backgroundImage: `url(${JSON.parse(goods.head_imgs)[0]})` }} key={y} onClick={() => navigate("/enterpriseGoodsDetails", { state: goods.id })}>
                                                        <div>￥{goods.sale_price}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="type">
                                        <div>经营类目：{item.category_name}</div>
                                        <div>
                                            <img src={require('../../../image/icon28.png')} />
                                            {item.city_name}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
            {
                !state.list.length ? <div className="empty">
                    <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/enterprise/empty.png" alt="" />
                    <p>暂无</p>
                </div> : null
            }
        </div>
    )
}

export default EnterpriseList;