import './index.scss'
import React, { useState, useEffect } from 'react'
import axios from '../../../util/axios'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'antd-mobile'
function EnterpriseSettlementInfo() {
  const navigate = useNavigate()
  let [info, setInfo] = useState({
    contract_picture: [],
    qualification: []
  })
  let getInfo = () => {
    axios.post('/JuTwitter/ChannelsCompany/info').then((res) => {
      if (res.code == 0) {
        res.result.contract_picture = JSON.parse(res.result.contract_picture)
        if (res.result.qualification) {
          res.result.qualification = JSON.parse(res.result.qualification)
        }
        if (!res.result.follow) {
          Modal.show({
            bodyStyle: { padding: '20px 0 0 0' },
            content: '为了享受更好的服务，请务必长按识别二维码，关注公众号！',
            closeOnMaskClick: !0,
            showCloseButton: !0,
            image: require('../../../image/weixin.jpg')
          })
        }
        setInfo(res.result)
      }
    })
  }
  useEffect(() => {
    document.title = '企业入驻'
    getInfo()
  }, [])
  return (
    <div className="settlement-info">
      <div className="speed">
        <div>
          <span>1</span>
          <span></span>
          <span>2</span>
          <span></span>
          <span>3</span>
        </div>
        <div>
          <span>{info.type == 1 ? '个体工商户信息' : '企业主体信息'} </span>
          <span>店铺信息</span>
          <span>审核中</span>
        </div>
        {info.status == -1 ? <div className="audit">审核中</div> : info.status == 2 ? <div className="reject">驳回原因：{info.remark}</div> : null}
      </div>
      <div className="settlement-name">{info.type == 1 ? '个体工商户' : '企业'}</div>
      <div className="personal-info">
        {info.type == 2 ? (
          <div>
            <span>企业名称</span>
            <span>{info.company_name}</span>
          </div>
        ) : null}
        <div>
          <span>法人姓名</span>
          <span>{info.name}</span>
        </div>
        <div>
          <span>法人身份证号码</span>
          <span>{info.id_number}</span>
        </div>
        <div>
          <span>联系方式</span>
          <span>{info.mobile}</span>
        </div>
        <div>
          <span>邮箱</span>
          <span>{info.email}</span>
        </div>
      </div>
      <div className="bank-info">
        <div className="info-item">
          <span>银行账户类型</span>
          <span>{info.bank_type == 1 ? '对公账户' : '对私账户'}</span>
        </div>
        <div className="info-item">
          <span>开户名称</span>
          <span>{info.bank_name}</span>
        </div>
        <div className="info-item">
          <span>开户账号</span>
          <span>{info.bank_card}</span>
        </div>
        <div className="info-item">
          <span>开户银行</span>
          <span>{info.bank_info}</span>
        </div>
        <div className="info-item">
          <span>开户省市地区</span>
          <span>
            {info.bank_province_name}
            {info.bank_city_name}
          </span>
        </div>
        <div className="identity">
          <div className="title">{info.type == 1 ? '身份证正反面' : '法人身份证正反面'}</div>
          <div className="card">
            <div>
              <img src={info.identity_card_front} />
            </div>
            <div>
              <img src={info.identity_card_back} />
            </div>
          </div>
        </div>
        <div className="contract">
          <div className="title">签合同照片</div>
          <div className="contract-img">
            {info.contract_picture.map((item, index) => {
              return <img src={item} key={index} />
            })}
          </div>
        </div>
        <div className="business-license">
          <div className="title">营业执照照片</div>
          <div className="img">
            <img src={info.business_license} />
          </div>
        </div>
      </div>
      <div className="shops">店铺</div>
      <div className="shop-infos">
        <div>
          <span>店铺名称</span>
          <span>{info.shop_name}</span>
        </div>
        <div>
          <span>店铺logo</span>
          <img src={info.shop_logo} />
        </div>
        <div>
          <span>所属区域</span>
          <span>
            {info.province_name}
            {info.city_name}
            {info.area_name}
          </span>
        </div>
        <div>
          <span>店铺地址</span>
          <span>{info.shop_address}</span>
        </div>
        <div>
          <span>点餐系统是否支持餐位+打包费</span>
          <span>{info.is_new_version ? '是' : '否'}</span>
        </div>
      </div>
      <div className="category">
        <div className="category-name">
          <span>经营类目</span>
          <span>{info.category_name}</span>
        </div>
        {info.qualification ? (
          <div className="category-img">
            <div>相关资质证件</div>
            <div>
              {info.qualification.map((item, index) => {
                return <img src={item} key={index} />
              })}
            </div>
          </div>
        ) : null}
      </div>
      {info.status == 2 ? (
        <div className="reset">
          <div onClick={() => navigate('/enterpriseSettlementApply', { state: 3 })}>重新提交</div>
        </div>
      ) : null}
    </div>
  )
}

export default EnterpriseSettlementInfo
