import style from './index.module.scss'
import React, { useState, useEffect } from 'react'
import { TextArea, Picker, Input, Modal, ImageUploader, Toast } from 'antd-mobile'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import axios from '../../../util/axios'

function AfterSales() {
  const location = useLocation()
  const data = location.state.data
  const status = location.state.state
  let [state, setState] = useState({
    refund_type: '',
    reason: '',
    refund_amount: '',
    order_goods_id: '',
    refund_user_type: '',
    refund_reason_id: '',
    images: ''
  })

  const refund_user_type = [
    [
      { label: '未收到商品', value: '1' },
      { label: '已收到商品', value: '2' }
    ]
  ]
  let [visibleRefund, setVisibleRefund] = useState(false)

  const refund_type = [
    [
      { label: '仅退款', value: '1' },
      { label: '退货退款', value: '2' }
    ]
  ]
  let [visibleRefundType, setVisibleRefundType] = useState(false)

  let [reasonList, setReasonList] = useState([])
  let [visibleReasonList, setVisibleReasonList] = useState(false)
  //  获取退款原因
  const getReasonList = (type) => {
    axios.post('/JuTwitter/Orderrefund/reasonList', { type }).then((res) => {
      if (res.code == 0) {
        let { list } = res.result
        let arr = list.map((item) => {
          return {
            label: item.title,
            value: item.id + ''
          }
        })
        setReasonList([arr])
      }
    })
  }

  const [fileList, setFileList] = useState([])
  const mockUpload = async (file) => {
    let formData = new FormData()
    formData.append('sn', localStorage.getItem('sn'))
    formData.append('utoken', localStorage.getItem('utoken'))
    formData.append('file', file)
    let res = await axios.uploadFile('/JuTwitter/Upload/index', formData)
    if (res.code === 0) {
      return {
        url: res.result.path
      }
    } else {
      Toast.show({
        content: res.msg
      })
      throw new Error('Fail to upload')
    }
  }

  useEffect(() => {
    if (status == 20) {
      setState({
        ...state,
        refund_user_type: '1',
        refund_type: '1',
        refund_amount: data.goods_revise_price > 0 ? data.goods_revise_price : data.max_refund_price,
        order_goods_id: data.id
      })
      getReasonList(1)
    } else {
      setState({
        ...state,
        refund_amount: data.goods_revise_price > 0 ? data.goods_revise_price : data.max_refund_price,
        order_goods_id: data.id
      })
    }
  }, [])
  return (
    <div className={style.contentbox}>
      <div className={style['goods-info']}>
        <img src={data.goods_img} alt="" />
        <div className={style.goodsDetails}>
          <div className={style.titleNum}>
            <div className={style.goodsTitle}>{data.goods_title}</div>
            <div className={style.goodsNum}>x{data.goods_num}</div>
          </div>
          <div className={style.specifications}>{data.goods_specs[0].attr_value}</div>
        </div>
      </div>
      <div className={style.content}>
        <div className={style.contentTop}>
          <div className={style.contentLeft}>
            商品状态<span>*</span>
          </div>
          <div
            style={{ color: state.refund_user_type && status == 30 ? '#333' : '#aeaeae' }}
            onClick={() => {
              if (status == 30) {
                setVisibleRefund(true)
              }
            }}
          >
            {state.refund_user_type ? (state.refund_user_type == 1 ? '未收到商品' : '已收到商品') : '请选择'}
            <img className={style.p_rightArrow} src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/p_rightArrow.png" />
          </div>
          <Picker
            columns={refund_user_type}
            visible={visibleRefund}
            onClose={() => {
              setVisibleRefund(false)
            }}
            value={state.refund_user_type}
            onConfirm={(v) => {
              setState({
                ...state,
                refund_user_type: v[0]
              })
            }}
          />
        </div>
        <div className={style.contentTop}>
          <div className={style.contentLeft}>
            处理方式<span>*</span>
          </div>
          <div
            style={{ color: state.refund_type && status == 30 ? '#333' : '#aeaeae' }}
            onClick={() => {
              if (status == 30) {
                setVisibleRefundType(true)
              }
            }}
          >
            {state.refund_type ? (state.refund_type == 1 ? '仅退款' : '退货退款') : '请选择'}
            <img className={style.p_rightArrow} src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/p_rightArrow.png" />
          </div>
          <Picker
            columns={refund_type}
            visible={visibleRefundType}
            onClose={() => {
              setVisibleRefundType(false)
            }}
            value={state.refund_type}
            onConfirm={(v) => {
              setState({
                ...state,
                refund_type: v[0]
              })
              getReasonList(v[0])
            }}
          />
        </div>
        <div className={style.contentTop}>
          <div className={style.contentLeft}>
            退款原因<span>*</span>
          </div>
          <div
            style={{ color: state.refund_reason_id ? '#333' : '#aeaeae' }}
            onClick={() => {
              if (state.refund_type) {
                setVisibleReasonList(true)
              }
            }}
          >
            {state.refund_reason_id ? reasonList[0].find((v) => v.value == state.refund_reason_id).label : '请选择'}
            <img className={style.p_rightArrow} src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/p_rightArrow.png" />
          </div>
        </div>
        <Picker
          columns={reasonList}
          visible={visibleReasonList}
          onClose={() => {
            setVisibleReasonList(false)
          }}
          value={state.refund_reason_id}
          onConfirm={(v) => {
            setState({
              ...state,
              refund_reason_id: v[0]
            })
          }}
        />
        <div className={style.contentBottom}>
          <div className={style.refundAmount}>
            <div className={style.contentLeft}>
              退款金额<span>*</span>
            </div>
            <div className={style.refundAmount_right}>
              <Input
                type="number"
                value={state.refund_amount}
                onChange={(val) => {
                  setState({
                    ...state,
                    refund_amount: val
                  })
                }}
                onBlur={() => {
                  if (state.refund_amount > data.max_refund_price) {
                    Modal.alert({
                      content: '退款金额大于最大退款金额，请修改',
                      closeOnMaskClick: true
                    })
                  }
                }}
              />
            </div>
          </div>
          <div className={style.tips}>可修改，最多￥{data.max_refund_price}</div>
        </div>
      </div>
      <div className={style.supplement}>
        <div className={style.supplementTop}>
          补充描述和凭证<span>*</span>
        </div>
        <TextArea
          rows={3}
          autoSize
          placeholder="补充描述，有助商家更好处理售后问题"
          className={style.supplementCenter}
          value={state.reason}
          onChange={(val) => {
            setState({
              ...state,
              reason: val
            })
          }}
        ></TextArea>
        <div className={style.supplementImage}>
          <ImageUploader
            multiple
            style={{ '--cell-size': '100px' }}
            value={fileList}
            onChange={setFileList}
            upload={mockUpload}
            maxCount={6}
            showUpload={fileList.length < 6}
            onCountExceed={(exceed) => {
              Toast.show(`最多选择 6 张图片，你多选了 ${exceed} 张`)
            }}
          />
        </div>
      </div>
      <div
        className={style.submit}
        onClick={() => {
          let data = { ...state }

          if (!data.refund_user_type) {
            return Toast.show('请选择商品状态')
          }
          if (!data.refund_type) {
            return Toast.show('请选择处理方式')
          }
          if (!data.refund_reason_id) {
            return Toast.show('请选择退款原因')
          }
          if (!data.refund_amount) {
            return Toast.show('请输入退款金额')
          }
          if (!data.reason) {
            return Toast.show('请输入补充描述')
          }
          data.images = fileList.map((item) => item.url)
          axios.post('/JuTwitter/Orderrefund/apply', data).then((res) => {
            if (res.code == 0) {
              Toast.show('提交成功')
              setTimeout(() => {
                window.history.go(-1)
              }, 2000)
            }
          })
        }}
      >
        提交
      </div>
    </div>
  )
}

export default AfterSales
