import "./index.scss";
import React, { useState, useEffect, useRef } from 'react';
import { Input, TextArea, Toast, Image } from 'antd-mobile';
import axios from "../../../util/axios";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
function Release() {
    const location = useLocation();
    const navigate = useNavigate();
    let [state, setState] = useState({
        title: '',
        cate_id: '',
        content: '',
        content_type: location.state,
        cover: [],
        cate: [],
        video: ''
    })
    let coverRef = useRef(null);
    let getCate = () => {
        axios.post("/JuTwitter/ForumsPosts/addPost").then(res => {
            if (res.code == 0) {
                console.log(res)
                setState({
                    ...state,
                    cate: res.result.cate.list
                })
            }
        })
    }
    let getFiles = async (type) => {
        let formData = new FormData();
        let files = coverRef.current.files;
        let _state = _.cloneDeep(state);
        formData.append('sn', localStorage.getItem("sn"));
        formData.append('utoken', localStorage.getItem("utoken"));
        for (let index = 0; index < files.length; index++) {
            formData.append('file', files[index])
            let result = await upFiles(formData);
            if (_state.content_type == 2) {
                _state.cover = [result.cover]
                _state.video = result.path
            } else {
                _state.cover.push(result.path)
            }
        }
        setState(_state)
    }
    let upFiles = (formData) => {
        return new Promise(resolve => {
            Toast.show({
                duration: 0,
                icon: 'loading',
                content: '上传中...',
            })
            axios.uploadFile("/JuTwitter/Upload/index", formData).then(res => {
                Toast.clear();
                if (res.code === 0) {
                    resolve(res.result);
                } else {
                    Toast.show({
                        content: res.msg,
                    })
                }
            })
        })
    }
    let release = () => {
        try {
            if (!state.title) throw "请填写标题";
            if (!state.content) throw "请填写内容";
            if (state.content_type == 2 && !state.cover.length) throw "请添加视频";
            if (state.content_type == 3 && !state.cover.length) throw "请添加图片";
            if (!state.cate_id) throw "请选择分类";
            let data = {
                title: state.title,
                cate_id: state.cate_id,
                content: state.content,
                content_type: state.content_type,
                cover: state.cover[0]
            }
            if (state.content_type == 3) data.content_data = state.cover;
            if (state.content_type == 2) data.content_data = state.video;
            axios.post("/JuTwitter/ForumsPosts/post", data).then(res => {
                if (res.code == 0) {
                    Toast.show({
                        content: "发布成功",
                        icon: "success"
                    })
                    navigate(-1);
                } else {
                    Toast.show({
                        content: res.msg
                    })
                }
            })
        } catch (e) {
            Toast.show({
                content: e
            })
        }
    }
    useEffect(() => {
        getCate();
    }, [])
    return (
        <div className="release">
            <div className="word">
                <div className="title">
                    <Input value={state.title} onChange={e => setState({ ...state, title: e })} placeholder="种草标题"></Input>
                </div>
                <div className="content">
                    <TextArea
                        placeholder='展开说说：分享你购买的好商品，好文章将会获得更多赞哦~'
                        autoSize={{ minRows: 5, maxRows: 10 }}
                        onChange={e => setState({ ...state, content: e })}
                    />
                </div>
            </div>
            {
                state.content_type == 3 ? <div className="images">
                    <div className="title">图片</div>
                    <div className="tips">建议图片尺寸750*800px，第一张默认为封面图哦~</div>
                    <div className="add">
                        <div className="img-list">
                            {
                                state.cover.map((item, index) => {
                                    return (
                                        <div className="img-item" key={index}>
                                            <Image src={item} fit="cover"></Image>
                                            {
                                                index == 0 ? <div className="first">封面</div> : null
                                            }
                                            <img className="remove" src={require("../../../image/community/icon5.png")} onClick={() => {
                                                let _state = _.cloneDeep(state);
                                                _state.cover.splice(index, 1);
                                                setState(_state);
                                            }} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="add-img">
                            <img src={require("../../../image/community/icon4.png")} alt="" />
                            <div>添加</div>
                            <input ref={coverRef} type="file" multiple accept="image/*" onChange={() => getFiles()} />
                        </div>
                    </div>
                </div> : <div className="video">
                    <div className="title">视频</div>
                    <div className="tips">第一张默认为封面图哦~</div>
                    <div className="add">
                        <div className="img-list">
                            {
                                state.cover.map((item, index) => {
                                    return (
                                        <div className="img-item" key={index}>
                                            <Image src={item} fit="cover"></Image>
                                            {
                                                index == 0 ? <div className="first">封面</div> : null
                                            }
                                            <img className="remove" src={require("../../../image/community/icon5.png")} onClick={() => {
                                                let _state = _.cloneDeep(state);
                                                _state.cover.splice(index, 1);
                                                setState(_state);
                                            }} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="add-img">
                            <img src={require("../../../image/community/icon6.png")} alt="" />
                            <div>添加</div>
                            <input ref={coverRef} type="file" accept="video/*" onChange={() => getFiles()} />
                        </div>
                    </div>
                </div>
            }
            <div className="classify">
                <div className="title">分类</div>
                <div className="cate-list">
                    {
                        state.cate.map((item, index) => {
                            return <div className={`${item.id == state.cate_id ? 'active' : ''}`} key={index} onClick={() => setState({ ...state, cate_id: item.id })}>{item.name}</div>
                        })
                    }
                </div>
            </div>
            <div className="confirm">
                <div onClick={() => release()}>发布</div>
            </div>
        </div>
    )
}


export default Release;