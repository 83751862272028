import style from "./index.module.scss";
import React, { useState, useEffect } from 'react';
import { SearchOutline } from 'antd-mobile-icons'
import { Toast } from 'antd-mobile'
import axios from "../../util/axios";
import Empty from "../../components/empty";
import _ from "lodash";
let is_getOrder = !0;
function Order() {
    let [list, setList] = useState([]);
    let [state, setState] = useState({
        status: '',
        keywords: '',
        page: 1,
        orderStatus: [{
            name: '全部',
            status: ''
        }, {
            name: '未付款',
            status: 0
        }, {
            name: '已付款',
            status: 1
        }, {
            name: '待完成',
            status: 2
        }, {
            name: '已完成',
            status: 3
        }, {
            name: '已失效',
            status: 4
        }]
    })
    let lists = [];
    let getOrderList = () => {
        axios.post("/JuTwitter/Order/index", {
            page: state.page,
            status: state.status,
            keywords: state.keywords,
            rows: 10
        }).then(res => {
            if (res.code == 0) {
                is_getOrder = !0;
                let list = res.result.list;
                let data = _.cloneDeep(lists);
                if (list.length) {
                    lists.push(...list);
                    data.push(...list);
                    setList(data);
                    setState({
                        ...state,
                        page: state.page++
                    })
                } else {
                    is_getOrder = !1;
                }
                console.log(res)
            } else {
                Toast.show({
                    content: res.msg
                })
            }
        }).catch(() => {
            is_getOrder = !0;
        })
    }
    let selectStatus = (status) => {
        if (status !== state.status) {
            setList([]);
            is_getOrder = !0;
            setState({
                ...state,
                status: status,
                page: 1
            })
        }
    }
    let center = (e) => {
        if (e.charCode === 13) {
            setList([]);
            is_getOrder = !0;
            setState({
                ...state,
                page: 1
            })
            getOrderList();
        }
    }
    let handelPageScroll = () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + windowHeight >= scrollHeight) {
            if (is_getOrder) {
                is_getOrder = !1;
                setTimeout(() => {
                    getOrderList();
                }, 400);
            }
        }
    }
    useEffect(() => {
        getOrderList();
    }, [state.status])
    useEffect(() => {
        document.title = "我的订单";
        window.addEventListener("scroll", handelPageScroll, false);
        return (() => {
            window.removeEventListener("scroll", handelPageScroll);
        })
    }, [])
    return (
        <div className={style.content}>
            <div className={style.header}>
                <div className={style.search}>
                    <SearchOutline fontSize={20} color="#999" />
                    <input type="text" placeholder="请输入商品名称" onKeyPress={(e) => center(e)} value={state.name} onChange={e => setState({ ...state, keywords: e.target.value })} />
                </div>
                <div className={style.status}>
                    {
                        state.orderStatus.map((item, index) => {
                            return (
                                <div className={item.status === state.status ? style.active : null} key={index} onClick={() => selectStatus(item.status)}>{item.name}</div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={style.list}>
                {
                    list.map((item, index) => {
                        return (
                            <div className={style.item} key={index}>
                                <div className={style.title}>
                                    <span>{item.order_title}</span><span style={{ color: item.status == 1 ? '#FF5A01' : item.status == 3 ? '#39D639' : item.status == 4 ? '#999' : '' }}>{item.status == 0 ? '未付款' : item.status == 1 ? '已付款' : item.status == 2 ? '待完成' : item.status == 3 ? '已完成' : '已失效'}</span>
                                </div>
                                <div className={style.order_con}>
                                    <div className={style.left}>
                                        <div>下单时间：{item.create_time}</div>
                                        <div>订单编号：{item.order_sn}</div>
                                    </div>
                                    <div className={style.right}>￥{item.pay_price}</div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {
                list.length == 0 ? <Empty text="暂无订单" marginTop={217 / 37.5 + 'rem'} /> : null
            }
        </div>
    )
}

export default Order