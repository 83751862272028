import "./index.scss";
import React, { useState, useEffect } from 'react';
import { Popup, ImageViewer } from 'antd-mobile';
import axios from "../../../util/axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Toast } from 'antd-mobile';
import { getDateformatText } from "../../../util/utils";
import _ from "lodash";
function PublishingCenter() {
    const navigate = useNavigate();
    const location = useLocation();
    let [state, setState] = useState({
        tabs: [{
            name: '已发布',
            type: 1
        }, {
            name: '收藏',
            type: 2
        }, {
            name: '赞过',
            type: 3
        }],
        id: '',
        type: 1,
        page: 1,
        userInfo: {},
        showHeadImg: !1
    })
    let is_getList = !0;
    let [visible, setVisible] = useState(!1)
    let [visible2, setVisible2] = useState(!1)
    let [list, setList] = useState([]);
    let timers = null;  // 防抖
    let touchType = 'shortTouch';   // 触摸类型是长按还是短按
    let isTouchmoveing = false // 是否move中（用于判断是否执行单点操作）
    let getList = () => {
        let data = {
            rows: 10,
            page: state.page,
            is_self: 1
        }
        if (location.state) {
            data.is_self = 0;
            data.openid = location.state.openid;
        }
        if (state.type == 1) data.my_post = 1;
        if (state.type == 2) data.collect_post = 1;
        if (state.type == 3) data.like_top = 1;
        axios.post("/JuTwitter/ForumsPosts/infoFromOther", data).then(res => {
            if (res.code == 0) {
                let my_post = [];
                let _state = _.cloneDeep(state);
                let _list = _.cloneDeep(list);
                if (state.type == 1) {
                    my_post = res.result.my_post.list;
                }
                if (state.type == 2) {
                    my_post = res.result.collect_post.list;
                }
                if (state.type == 3) {
                    my_post = res.result.like_top.list;
                }
                _state.userInfo = res.result.member;
                if (my_post.length) {
                    if (location.state) res.result.member.is_follow = location.state.is_follow;
                    _list.push(...my_post);
                    setList(_list);
                    _state.page++;
                }
                setState(_state);

            } else {
                Toast.show({
                    content: res.msg
                })
            }
        })
    }
    let confirmRemove = () => {
        axios.post("/JuTwitter/ForumsPosts/deletePost", {
            id: state.id
        }).then(res => {
            if (res.code == 0) {
                let _list = _.cloneDeep(list);
                _list = _list.filter(item => item.id != state.id);
                setList(_list)
                setVisible2(!1);
                Toast.show({
                    content: "删除成功",
                    icon: "success"
                })
            } else {
                Toast.show({
                    content: res.msg
                })
            }
        })
    }
    let follow = () => {
        axios.post("/JuTwitter/ForumsPosts/collectWechatUser", {
            id: state.userInfo.id,
            status: state.userInfo.is_follow ? 0 : 1
        }).then(res => {
            if (res.code == 0) {
                let _state = _.cloneDeep(state);
                _state.userInfo.is_follow = _state.userInfo.is_follow ? 0 : 1;
                Toast.show({
                    content: _state.userInfo.is_follow ? '已关注' : '已取消'
                })
                setState(_state);
            } else {
                Toast.show({
                    content: res.msg
                })
            }
        })
    }
    let onTouchStart = (e) => {
        if (timers) return
        timers = setTimeout(() => {
            clearTimeout(timers)
            timers = null
            touchType = "longTouch"
        }, 500)
    }
    let onTouchMove = (e) => {
        isTouchmoveing = true
        if (!timers) return   // 节流操作
        // 长按下一移动就取消长按效果
        clearTimeout(timers)
        timers = null
    }
    let onTouchEnd = (e, id) => {
        if (touchType === 'shortTouch' && !isTouchmoveing) {
            navigate("/communityDetails", { state: id })
        } else if (touchType === 'longTouch' && !location.state) {
            setState({
                ...state,
                id: id
            })
            setVisible2(!0);
        }
        e.preventDefault();
        clearTimeout(timers)
        // 复原初始化数据
        timers = null
        touchType = 'shortTouch'
        isTouchmoveing = false
    }
    
    useEffect(() => {
        getList();
    }, [state.type])
    useEffect(() => {
        document.title = "发布中心";
    }, [])
    return (
        <div className="publishing-center" onScroll={(e) => {
            let { clientHeight, scrollHeight, scrollTop } = e.target;
            if (scrollTop + clientHeight + 40 > scrollHeight) {
                if (is_getList) {
                    is_getList = !1;
                    getList();
                }
            }
        }}>
            <div className="header" style={{ backgroundImage: "url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/back.png)" }}>
                <div className="user-info">
                    <div>
                        <img src={state.userInfo.headimgurl} onClick={() => setState({ ...state, showHeadImg: !0 })} />
                        <span>{state.userInfo.nickname}</span>

                    </div>
                    {
                        location.state ? <div className={`${state.userInfo.is_follow ? 'follow' : ''}`} onClick={() => follow()}>{state.userInfo.is_follow ? '已关注' : '关注'}</div> : null
                    }

                </div>
                <div className="total-num">
                    <div onClick={() => navigate("/follow", { state: { type: 1, is_self: location.state ? 0 : 1, openid: location.state?.openid } })}>关注 {state.userInfo.follow_other || 0}</div>
                    <div onClick={() => navigate("/follow", { state: { type: 2, is_self: location.state ? 0 : 1, openid: location.state?.openid } })}>粉丝 {state.userInfo.fans || 0}</div>
                    <div>获赞 {state.userInfo.total_like || 0}</div>
                </div>
            </div>
            <div className="tabs">
                {
                    state.tabs.map((item, index) => {
                        return (
                            <div key={index} className={`${item.type == state.type ? 'active' : ''}`} onClick={() => {
                                if (state.type != item.type) {
                                    setState({
                                        ...state,
                                        page: 1,
                                        type: item.type
                                    })
                                    setList([]);
                                }
                            }}>{item.name}</div>
                        )
                    })
                }
            </div>
            <div className="list">
                <div className="left">
                    {
                        list.map((item, index) => {
                            return Number(index + 1) % 2 == 1 ? <div className="item" key={index} onTouchStart={(e) => onTouchStart(e)} onTouchMove={(e) => onTouchMove(e)} onTouchEnd={(e) => onTouchEnd(e, item.id)}>
                                <img className="cover" src={item.cover} alt="" />
                                <div className="title">{item.title}</div>
                                <div className="date">
                                    <div>{getDateformatText(item.create_time)} 发布</div>
                                    <div>
                                        <img src={require(`../../../image/community/icon${item.like_num ? 1 : 7}.png`)} alt="" />
                                        {item.like_num ? <span>{item.like_num >= 1000 ? parseFloat((item.like_num / 1000).toFixed(2)) + 'k' : item.like_num}</span> : null}
                                    </div>
                                </div>
                            </div> : null
                        })
                    }
                </div>
                <div className="right">
                    {
                        list.map((item, index) => {
                            return Number(index + 1) % 2 == 0 ? <div className="item" key={index} onTouchStart={(e) => onTouchStart(e)} onTouchMove={(e) => onTouchMove(e)} onTouchEnd={(e) => onTouchEnd(e, item.id)}>
                                <img className="cover" src={item.cover} alt="" />
                                <div className="title">{item.title}</div>
                                <div className="date">
                                    <div>{getDateformatText(item.create_time)} 发布</div>
                                    <div>
                                        <img src={require(`../../../image/community/icon${item.like_num ? 1 : 7}.png`)} alt="" />
                                        {item.like_num ? <span>{item.like_num >= 1000 ? parseFloat((item.like_num / 1000).toFixed(2)) + 'k' : item.like_num}</span> : null}
                                    </div>
                                </div>
                            </div> : null
                        })
                    }
                </div>
            </div>
            {
                !list.length ? <div className={`no ${state.type == 1 ? 'release-no' : state.type == 2 ? 'collect-no' : 'like-no'}`}>
                    <img src={state.type == 1 ? require("../../../image/community/img3.png") : state.type == 2 ? require("../../../image/community/img4.png") : require("../../../image/community/img5.png")} alt="" />
                    <p>{state.type == 1 ? '暂无发布记录' : state.type == 2 ? '暂无收藏记录' : '暂无点赞记录'}</p>
                    {
                        !location.state ? <div onClick={() => {
                            if (state.type == 1) setVisible(!0);
                            if (state.type == 2 || state.type == 3) navigate("/community");
                        }}>{state.type == 1 ? '去发布' : state.type == 2 ? '去收藏' : '去点赞'}</div> : null
                    }
                </div> : null
            }
            {
                !location.state ? <div className="release-btn" onClick={() => setVisible(!0)}>
                    <img src={require("../../../image/community/icon2.png")} alt="" />
                    去发布
                </div> : null
            }
            <Popup
                visible={visible}
                bodyStyle={{ height: '100vh' }}
            >
                <div className="release-type" style={{ backgroundImage: "url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/back1.png)" }}>
                    <div>发布</div>
                    <div>快来发布你的种草吧~</div>
                    <div>
                        <img src={require("../../../image/community/img1.png")} onClick={() => navigate("/release", { state: 3 })} />
                        <img src={require("../../../image/community/img2.png")} onClick={() => navigate("/release", { state: 2 })} />
                    </div>
                    <div onClick={() => setVisible(!1)}>
                        <img src={require("../../../image/community/icon3.png")} alt="" />
                    </div>
                </div>
            </Popup>
            <Popup
                visible={visible2}
            >
                <div className="remove-modal">
                    <div>确定删除此内容么？</div>
                    <div onClick={() => confirmRemove()}>确认删除</div>
                    <div onClick={() => setVisible2(!1)}>取消</div>
                </div>
            </Popup>
            <ImageViewer
                image={state.userInfo.headimgurl}
                visible={state.showHeadImg}
                onClose={() => {
                    setState({ ...state, showHeadImg: !1 })
                }}
            />
        </div>
    )
}

export default PublishingCenter;