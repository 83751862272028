import "./index.scss";
import React, {useEffect } from 'react';
import { useNavigate } from "react-router-dom";
// import DrawLottery from '../../../components/drawLottery';
function EnterpriseSettlementIndex() {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = "企业入驻";
    })
    return (
        <div className="settlement-index">
            <div className="individual" style={{ backgroundImage: "url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/enterprise/img1.png)" }}>
                <div>个体工商户入驻</div>
                <div>欢迎入驻 微码惠 大家庭~</div>
                <div onClick={()=> navigate("/enterpriseSettlementApply",{state:1})}>立即入驻</div>
            </div>
            <div className="enterprise" style={{ backgroundImage: "url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/enterprise/img2.png)" }}>
                <div>企业入驻</div>
                <div>欢迎入驻 微码惠 大家庭~</div>
                <div onClick={()=> navigate("/enterpriseSettlementApply",{state:2})}>立即入驻</div>
            </div>
            {/* <DrawLottery /> */}
        </div>
    )
}

export default EnterpriseSettlementIndex;