import Index from '../views/index'
import MyWallet from '../views/myWallet'
import My from '../views/my'
import MyAddress from '../views/myAddress'
import AddAddress from '../views/addAddress'
import Activation from '../views/activation'
import ActivationApply from '../views/activationApply'
import ReviewProgress from '../views/reviewProgress'
import BalanceDetails from '../views/balanceDetails'
import Order from '../views/order'
import Withdrawal from '../views/withdrawal'
import AddCard from '../views/addCard'
import WithdrawalLog from '../views/withdrawalLog'
import Life from '../views/life'
import Activated from '../views/activated'
import Shopping from '../views/shopping'
import Pay from '../views/pay'
import PaySuccess from '../views/paySuccess'
import PointsMall from '../views/pointsMall/index'
import GoodsDetails from '../views/pointsMall/goodsDetails'
import ConfirmOrder from '../views/pointsMall/confirmOrder'
import IntegralDetails from '../views/pointsMall/integralDetails'
import IntegalOrder from '../views/pointsMall/integralOrder'
import Logistics from '../views/logistics'
import PublishingCenter from '../views/publishingCenter/index'
import Release from '../views/publishingCenter/release'
import Community from '../views/community'
import CommunityDetails from '../views/communityDetails'
import Follow from '../views/follow'
import EnterpriseSettlementIndex from '../views/enterpriseSettlement/index'
import EnterpriseSettlementInfo from '../views/enterpriseSettlement/info'
import EnterpriseSettlementApply from '../views/enterpriseSettlement/apply'
import DrawLotteryRules from '../views/drawLotteryRules'
import EnterpriseList from '../views/enterprise/list'
import EnterpriseInfo from '../views/enterprise/info'
import EnterpriseShop from '../views/enterprise/shop'
import EnterpriseOrder from '../views/enterprise/order'
import AfterSales from '../views/enterprise/afterSales'
import AfterSalesProgress from '../views/enterprise/afterSalesProgress'
import ReturnInfomation from '../views/enterprise/returnInfomation'
import EnterpriseOrderDetails from '../views/enterprise/orderDetails'
import EnterpriseGoodsDetails from '../views/goodsDetails/index'
import ConfirmGoodsOrder from '../views/goodsDetails/confirmGoodsOrder'
import Cart from '../views/cart'

let routers = [
  {
    title: '微码惠',
    path: '/',
    component: Index
  },
  {
    title: '个人中心',
    path: '/my',
    component: My
  },
  {
    title: '我的地址',
    path: '/myAddress',
    component: MyAddress
  },
  {
    title: '添加地址',
    path: '/addAddress',
    component: AddAddress
  },
  {
    title: '钱包',
    path: '/MyWallet',
    component: MyWallet
  },
  {
    title: '店铺设置',
    path: '/activation',
    component: Activation
  },
  {
    title: '微码惠',
    path: '/activationApply',
    component: ActivationApply
  },
  {
    title: '审核进度',
    path: '/reviewProgress',
    component: ReviewProgress
  },
  {
    title: '提现明细',
    path: '/balanceDetails',
    component: BalanceDetails
  },
  {
    title: '我的订单',
    path: '/order',
    component: Order
  },
  {
    title: '提现',
    path: '/withdrawal',
    component: Withdrawal
  },
  {
    title: '添加银行卡',
    path: '/addCard',
    component: AddCard
  },
  {
    title: '提现记录',
    path: '/withdrawalLog',
    component: WithdrawalLog
  },
  {
    title: '生活服务',
    path: '/life',
    component: Life
  },
  {
    title: '微码惠',
    path: '/activated',
    component: Activated
  },
  {
    title: '购物',
    path: '/shopping',
    component: Shopping
  },
  {
    title: '向商户付款',
    path: '/pay',
    component: Pay
  },
  {
    title: '微码惠',
    path: '/alipaySuccess',
    component: PaySuccess
  },
  ,
  {
    title: '积分商城',
    path: '/pointsMall',
    component: PointsMall
  },
  {
    title: '商品详情',
    path: '/goodsDetails',
    component: GoodsDetails
  },
  {
    title: '积分确认订单',
    path: '/confirmOrder',
    component: ConfirmOrder
  },
  {
    title: '积分明细',
    path: '/integralDetails',
    component: IntegralDetails
  },
  {
    title: '积分订单',
    path: '/integalOrder',
    component: IntegalOrder
  },
  {
    title: '物流信息',
    path: '/logistics',
    component: Logistics
  },
  {
    title: '发布中心',
    path: '/publishingCenter',
    component: PublishingCenter
  },
  {
    title: '发布中心',
    path: '/release',
    component: Release
  },
  {
    title: '种草社区',
    path: '/community',
    component: Community
  },
  {
    title: '种草社区',
    path: '/communityDetails',
    component: CommunityDetails
  },
  {
    title: '种草社区',
    path: '/follow',
    component: Follow
  },
  {
    title: '企业入驻',
    path: '/enterpriseSettlementIndex',
    component: EnterpriseSettlementIndex
  },
  {
    title: '企业入驻',
    path: '/enterpriseSettlementInfo',
    component: EnterpriseSettlementInfo
  },
  {
    title: '企业入驻',
    path: '/enterpriseSettlementApply',
    component: EnterpriseSettlementApply
  },
  {
    title: '企业列表',
    path: '/enterpriseList',
    component: EnterpriseList
  },
  {
    title: '企业信息',
    path: '/enterpriseInfo',
    component: EnterpriseInfo
  },
  {
    title: '企业列表',
    path: '/enterpriseShop',
    component: EnterpriseShop
  },
  {
    title: '企业订单',
    path: '/enterpriseOrder',
    component: EnterpriseOrder
  },
  {
    title: '订单详情',
    path: '/enterpriseOrderDetails',
    component: EnterpriseOrderDetails
  },
  {
    title: '申请售后',
    path: '/afterSales',
    component: AfterSales
  },
  {
    title: '维权进度',
    path: '/AfterSalesProgress',
    component: AfterSalesProgress
  },
  {
    title: '退货信息',
    path: '/ReturnInfomation',
    component: ReturnInfomation
  },
  {
    title: '摇奖规则',
    path: '/drawLotteryRules',
    component: DrawLotteryRules
  },
  {
    title: '商品详情',
    path: '/enterpriseGoodsDetails',
    component: EnterpriseGoodsDetails
  },
  {
    title: '提交订单',
    path: '/confirmGoodsOrder',
    component: ConfirmGoodsOrder
  },
  {
    title: '购物车',
    path: '/cart',
    component: Cart
  }
]

export default routers
