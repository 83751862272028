import './index.scss'
import React, { useState, useEffect } from 'react'
import { Swiper, Image, Popup, Stepper, Toast } from 'antd-mobile'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from '../../../util/axios'
import _ from 'lodash'
function EnterpriseGoodsDetails() {
  let [info, setInfo] = useState({
    skus: []
  })
  const navigate = useNavigate()
  const location = useLocation()
  let [state, setState] = useState({
    showSpec: !1,
    skusIndex: 0,
    goods_num: 1,
    cart_num: 0
  })
  let getGoodsInfo = () => {
    axios
      .post('/JuTwitter/CompanyGoods/info', {
        id: location.state
      })
      .then((res) => {
        if (res.code == 0) {
          let info = res.result
          let goods_details = ''
          info.head_imgs = JSON.parse(info.head_imgs)
          info.desc_info = JSON.parse(info.desc_info)
          for (let i in info.desc_info.imgs) {
            goods_details += `<p><img src="${info.desc_info.imgs[i]}" /></p>`
          }
          for (let i in info.skus) {
            info.skus[i].sku_attrs = JSON.parse(info.skus[i].sku_attrs)
          }
          info.goods_details = goods_details
          setInfo(info)
        }
      })
  }
  let getCartNum = () => {
    axios.post('/JuTwitter/Cart/totalNum').then((res) => {
      if (res.code == 0) {
        setState({
          ...state,
          cart_num: res.result.total
        })
      }
    })
  }
  let chooseSpecifications = (item, index) => {
    setState({
      ...state,
      skusIndex: index
    })
  }
  let addCart = () => {
    let data = {
      goods_sku_id: info.skus[state.skusIndex].id,
      quantity: state.goods_num,
      goods_id: info.id
    }
    if (info.skus_cps.length) {
      data.goods_skus_cps_id = info.skus_cps[state.skusIndex].id
    }
    axios.post('/JuTwitter/Cart/add', data).then((res) => {
      if (res.code == 0) {
        Toast.show({
          content: '添加购物车成功'
        })
        setState({
          ...state,
          showSpec: !1,
          cart_num: Number(state.cart_num) + Number(state.goods_num)
        })
      } else {
        Toast.show({
          content: res.msg
        })
      }
    })
  }
  useEffect(() => {
    getGoodsInfo()
    getCartNum()
  }, [])
  return (
    <>
      {info.id ? (
        <>
          <div className="goods-img">
            <Swiper
              style={{
                '--height': '100%'
              }}
              loop
              indicator={(total, current) => <div className="customIndicator">{`${current + 1} / ${total}`}</div>}
            >
              {info.head_imgs?.map((item, index) => {
                return (
                  <Swiper.Item key={index}>
                    <Image src={item} fit="cover" />
                  </Swiper.Item>
                )
              })}
            </Swiper>
          </div>
          <div className="goods-info">
            <div className="price">
              <div>
                <p>{info.skus_cps.length ? info.skus_cps[state.skusIndex].new_sale_price : info.skus[state.skusIndex].sale_price}</p>
                {Number(info.skus[state.skusIndex].market_price) ? <p>￥{info.skus[state.skusIndex].market_price}</p> : null}
              </div>
              <div>销量：{info.sales}</div>
            </div>
            <div className="goods-name">{info.title}</div>
            <div className="sub-title">{info.sub_title}</div>
          </div>
          <div className="shop-info">
            <div>
              <img src={info.shop.shop_logo} alt="" />
              <span>{info.shop.shop_name}</span>
            </div>
            <div onClick={() => navigate('/enterpriseShop', { state: info.shop.id })}>进店逛逛</div>
          </div>
          <div className="goods-details">
            <div className="title">— 商品详情 —</div>
            <div dangerouslySetInnerHTML={{ __html: info.goods_details }}></div>
          </div>
          <div className="operate-wrap">
            <div className="left">
              <div onClick={() => navigate('/enterpriseShop', { state: info.shop.id })}>
                <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/shop_z.png" alt="" />
                <p>店铺</p>
              </div>
              <div onClick={() => navigate('/cart')}>
                <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/shopcar_z.png" alt="" />
                <p>购物车</p>
                <div className="cart-num">{state.cart_num}</div>
              </div>
            </div>
            <div className="right">
              <div
                onClick={() => {
                  setState({
                    ...state,
                    showSpec: !0
                  })
                }}
              >
                加入购物车
              </div>
              <div
                onClick={() => {
                  setState({
                    ...state,
                    showSpec: !0
                  })
                }}
              >
                立即购买
              </div>
            </div>
          </div>
          <Popup
            visible={state.showSpec}
            showCloseButton
            onMaskClick={() => {
              setState({ ...state, showSpec: !1 })
            }}
            onClose={() => {
              setState({ ...state, showSpec: !1 })
            }}
            bodyStyle={{
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px'
            }}
          >
            <div className="spec-wrap">
              <div className="spec-goosd-info">
                <img src={info.skus[state.skusIndex].thumb_img} alt="" />
                <div className="name-price">
                  <div>{info.title}</div>
                  <div>
                    <p>
                      ￥{info.skus_cps.length ? info.skus_cps[state.skusIndex].new_sale_price : info.skus[state.skusIndex].sale_price} <span>￥{info.skus[state.skusIndex].market_price}</span>
                    </p>
                    <span>库存：{info.skus[state.skusIndex].stock_num}</span>
                  </div>
                </div>
              </div>
              <div className="spec-info">
                <div className="line"></div>
                <div className="spec-list">
                  <div className="title">规格：</div>
                  <div className="details">
                    {info.skus.map((item, index) => {
                      return (
                        <div className={`item ${state.skusIndex == index ? 'active' : ''}`} key={index} onClick={() => chooseSpecifications(item, index)}>
                          {item.sku_attrs[0].attr_value}
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="add-num">
                  <div>购买数量</div>
                  <Stepper
                    value={state.goods_num}
                    min={1}
                    onChange={(value) => {
                      setState({
                        ...state,
                        goods_num: value
                      })
                    }}
                  />
                </div>
              </div>
              <div className="operate-btn">
                <div onClick={() => addCart()}>加入购物车</div>
                <div
                  onClick={() => {
                    let skus_info = info.skus[state.skusIndex]
                    let skus_cps_info = info.skus_cps[state.skusIndex]
                    let obj = {
                      sku_id: skus_info.id,
                      company_id: info.company_id,
                      goods_type: 2,
                      goods_num: state.goods_num,
                      goods_id: info.id
                    }
                    if (info.skus_cps.length) {
                      obj.goods_skus_cps_id = info.skus_cps[state.skusIndex].id
                    }
                    navigate('/confirmGoodsOrder', {
                      state: {
                        cart_way: 'right_now',
                        company_id: info.company_id,
                        goods_total_num: state.goods_num,
                        sku_json: [obj],
                        shopList: [
                          {
                            shop_logo: info.shop.shop_logo,
                            shop_name: info.shop.shop_name,
                            company_id: info.company_id,
                            goods_amount: skus_cps_info ? skus_cps_info.new_sale_price * state.goods_num : skus_info.sale_price * state.goods_num,
                            goodsList: [
                              {
                                price: skus_cps_info ? skus_cps_info.new_sale_price : skus_info.sale_price,
                                num: state.goods_num,
                                img: skus_info.thumb_img,
                                title: info.title,
                                sku_name: skus_info.sku_attrs[0].attr_value
                              }
                            ]
                          }
                        ]
                      }
                    })
                  }}
                >
                  立即购买
                </div>
              </div>
            </div>
          </Popup>
        </>
      ) : null}
    </>
  )
}

export default EnterpriseGoodsDetails
