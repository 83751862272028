import "./index.scss";
import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import axios from "../../../util/axios";
function EnterpriseInfo() {
    const location = useLocation();
    let [info, setInfo] = useState({});
    let [showHand, setShowHand] = useState(!1);
    useEffect(() => {
        axios.post("/JuTwitter/Company/info", {
            id: location.state
        }).then(res => {
            if (res.code == 0) {
                setInfo(res.result);
            }
        })
    }, [])
    return (
        <div className="enterprise-infos">
            {
                showHand ? <img className="hand" src={require("../../../image/community/icon14.png")} alt="" /> : null
            }
            <div className="top">
                <div className="info">
                    <img className="logo" src={info.shop_logo} />
                    <div className="name">
                        <p>{info.shop_name}</p>
                        <img src={require("../../../image/icon29.png")} />
                    </div>
                    <div className="share" onClick={() => {
                        setShowHand(!0);
                        setTimeout(() => {
                            setShowHand(!1);
                        }, 8000)
                    }}>分享</div>
                </div>
                {
                    info.shop_Introduction ? <div className="introduction">{info.shop_Introduction}</div> : null
                }
            </div>
            <div className="center">
                <div className="address">
                    <span>地址</span>
                    <div>{info.shop_address}</div>
                </div>
                <div className="phone">
                    <span>电话</span>
                    <div>{info.mobile}</div>
                </div>
                <div className="business_license">
                    <div className="title">
                        <span>认证</span>
                        <div>
                            <img src={require("../../../image/renzhen.png")} />
                            <p>该企业已完成企业资质认证</p>
                        </div>
                    </div>
                    <div className="img">
                        <img src={info.business_license} />
                    </div>
                </div>
                <div className="category">
                    <div className="title">
                        <span>类目</span>
                        <div>{info.category_name}</div>
                    </div>
                    <div className="img">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnterpriseInfo;