import "./index.scss";
import React, { useState, useEffect } from 'react';
import axios from "../../util/axios";
import { Toast } from "antd-mobile";
import _ from "lodash";
import Empty from "../../components/empty";
import { useLocation, useNavigate } from "react-router-dom";
function Follow() {
    const location = useLocation();
    const navigate = useNavigate();
    let [state, setState] = useState({
        page: 1,
        list: [],
        tabs: [{
            name: '关注',
            type: 1
        }, {
            name: '粉丝',
            type: 2
        }],
        type: location.state.type
    })
    let is_getList = !0;
    let getList = () => {
        let data = {
            page: state.page,
            rows: 10
        }
        if (!location.state.is_self) data.openid = location.state.openid;
        axios.post(state.type == 1 ? "/JuTwitter/ForumsPosts/followList" : "/JuTwitter/ForumsPosts/fansList", data).then(res => {
            if (res.code == 0) {
                let list = res.result;
                let _state = _.cloneDeep(state);
                if (list.length) {
                    list.map(item => item.is_follow = !0)
                    _state.page++;
                    _state.list.push(...list);
                }
                if (_state.type == 1) _state.tabs[0].name = '关注 ' + list.length;
                if (_state.type == 2) _state.tabs[1].name = '粉丝 ' + list.length;
                setState(_state);
            }
        })
    }
    let follow = (item, index, e) => {
        if (state.type == 1) {
            axios.post("/JuTwitter/ForumsPosts/collectWechatUser", {
                id: item.info.id,
                status: item.is_follow ? 0 : 1
            }).then(res => {
                if (res.code == 0) {
                    let _state = _.cloneDeep(state);
                    _state.list[index].is_follow = item.is_follow ? 0 : 1;
                    setState(_state);
                } else {
                    Toast.show({
                        content: res.msg
                    })
                }
            })
        }
        if (state.type == 2) {
            axios.post("/JuTwitter/ForumsPosts/collectWechatUser", {
                id: item.info.id,
                status: item.is_double_follows ? 0 : 1
            }).then(res => {
                if (res.code == 0) {
                    let _state = _.cloneDeep(state);
                    _state.list[index].is_double_follows = item.is_double_follows ? 0 : 1;
                    setState(_state);
                } else {
                    Toast.show({
                        content: res.msg
                    })
                }
            })
        }
        e.stopPropagation();
    }
    useEffect(() => {
        getList();
    }, [state.type])
    return (
        <div className="follow" onScroll={(e) => {
            let { clientHeight, scrollHeight, scrollTop } = e.target;
            if (scrollTop + clientHeight + 20 > scrollHeight) {
                if (is_getList) {
                    is_getList = !1;
                    getList();
                }
            }
        }}>
            <div className="tabs">
                {
                    state.tabs.map((item, index) => {
                        return (
                            <div className={`${state.type == item.type ? 'active' : ''}`} key={index} onClick={() => {
                                if (state.type != item.type) {
                                    setState({
                                        ...state,
                                        list: [],
                                        page: 1,
                                        type: item.type
                                    })
                                }
                            }}>{item.name}</div>
                        )
                    })
                }
            </div>
            <div className="list">
                {
                    state.list.map((item, index) => {
                        return (
                            <div className="item" key={index} onClick={() => navigate("/publishingCenter", { state: { openid: item.info.openid, is_follow: item.is_follow } })}>
                                <div className="left">
                                    <img src={item.info.headimgurl} alt="" />
                                    <div>
                                        <p>{item.info.nickname}</p>
                                        <p>粉丝 {item.info.total_fans}</p>
                                    </div>
                                </div>
                                {
                                    location.state.is_self ? <>
                                        {
                                            state.type == 1 ? <div className={`right ${!item.is_follow ? 'right-active' : ''}`} onClick={(e) => follow(item, index, e)}>{item.is_follow ? '已关注' : '关注'}</div> : <div className={`right ${!item.is_double_follows ? 'right-active' : ''}`} onClick={(e) => follow(item, index, e)}>{item.is_double_follows ? '互相关注' : '回粉'}</div>
                                        }
                                    </> : null
                                }
                            </div>
                        )
                    })
                }

            </div>
            {
                state.list.length == 0 ? <Empty text="暂无内容" marginTop={180 / 37.5 + 'rem'} /> : null
            }
        </div>
    )
}

export default Follow;