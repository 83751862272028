import style from "./index.module.scss";
import React, { useState, useEffect } from 'react';
import axios from "../../util/axios";
import { getDateformat } from "../../util/utils";
import Empty from "../../components/empty";
import _ from "lodash";
let is_getList = !0;
function BalanceDetails() {
    let [list, setList] = useState([]);
    let [state, setState] = useState({
        income_type: '',
        page: 1,
        type: [{
            name: '全部',
            income_type: ''
        }, {
            name: '收入',
            income_type: 1
        }, {
            name: '支出',
            income_type: 2
        }]
    })
    let lists = [];
    let getList = () => {
        axios.post("/JuTwitter/User/getBalanceLog", {
            income_type: state.income_type,
            page: state.page,
            rows: 10
        }).then(res => {
            if (res.code == 0) {
                is_getList = !0;
                let list = res.result.list;
                let data = _.cloneDeep(lists);
                if (list.length) {
                    lists.push(...list);
                    data.push(...list);
                    setList(data);
                    setState({
                        ...state,
                        page: state.page++
                    })
                } else {
                    is_getList = !1
                }
            }
        }).catch(() => {
            is_getList = !0;
        })
    }
    let selectType = (income_type) => {
        if (income_type != state.income_type) {
            setList([]);
            is_getList = !0;
            setState({
                ...state,
                page: 1,
                income_type: income_type
            })
        }
    }
    let handelPageScroll = () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + windowHeight >= scrollHeight) {
            if (is_getList) {
                is_getList = !1;
                setTimeout(() => {
                    getList();
                }, 400);
            }
        }
    }
    useEffect(() => {
        getList();
    }, [state.income_type])
    useEffect(() => {
        document.title = "提现明细";
        window.addEventListener("scroll", handelPageScroll, false);
        return (() => {
            window.removeEventListener("scroll", handelPageScroll);
        })
    }, [])
    return (
        <div className={style.content}>
            <div className={style.type}>
                {
                    state.type.map((item, index) => {
                        return (
                            <div className={item.income_type === state.income_type ? style.active : null} key={index} onClick={() => selectType(item.income_type)}>{item.name}</div>
                        )
                    })
                }
            </div>
            <div className={style.list}>
                {
                    list.map((item, index) => {
                        return (
                            <div className={style.item} key={index}>
                                <div className={style.left}>
                                    <div>{item.type == 1 ? '天天领现金红包' : '提现'}</div>
                                    <div>{getDateformat(item.create_time)}</div>
                                </div>
                                <div className={style.right}>{item.income_type == 1 ? '+' : '-'}{item.amount}</div>
                            </div>
                        )
                    })
                }
            </div>
            {
                list.length === 0 ? <Empty text="暂无数据" marginTop={200 / 37.5 + 'rem'} /> : null
            }
        </div>
    )
}

export default BalanceDetails