import React, { useState, useEffect } from 'react';
import { Swiper, Image, SpinLoading, Toast } from 'antd-mobile'
import { useDispatch } from 'react-redux';
import { RightOutline } from 'antd-mobile-icons'
import axios from '../../../../util/axios';
import style from "./index.module.scss";
import connect from "../../../../image/connect.png";
import _ from "lodash";
import logo6 from "../../../../image/logo6.png";
import logo7 from "../../../../image/logo7.png";
import logo8 from "../../../../image/logo8.png";
import logo9 from "../../../../image/logo9.png";
import logo10 from "../../../../image/logo10.png";
import icon11 from "../../../../image/icon11.png";
function Index(props) {
    const [info, setInfo] = useState({});
    const [goodsList, setGoodsList] = useState([]);
    const [loading, setLoading] = useState(!1);
    let dispatch = useDispatch();
    let storeLogo = [logo6, logo7, logo8, logo9, logo10];
    let page = 2;
    let goods_list = [];
    let getShopInfo = () => {
        let result = props.info;
        let chain_restaurant = [];
        result.display_window.map((item, index) => {
            item.img = storeLogo[index]
            return item;
        })
        while (result.chain_restaurant.length > 0) {
            chain_restaurant.push(result.chain_restaurant.splice(0, 5));
        }
        result.chain_restaurant = chain_restaurant;
        dispatch({ type: 'setTabberInfo', data: result.bottom })
        goods_list = result.goods_list;
        setGoodsList(result.goods_list);
        setInfo(result);
    }
    let is_getGoodsList = !0;
    let getGoodsList = () => {
        setLoading(!0);
        setTimeout(() => {
            axios.post("/JuTwitter/Page/goodsList", {
                page: page,
                rows: 10,
            }).then(res => {
                setLoading(!1);
                if (res.code == 0) {
                    is_getGoodsList = !0;
                    let list = res.result.goods_list;
                    let goods = _.cloneDeep(goods_list);
                    if (list.length) {
                        page++;
                        goods_list.push(...list);
                        goods.push(...list);
                        setGoodsList(goods);
                    } else {
                        is_getGoodsList = !1;
                    }
                }
            }).catch(() => {
                is_getGoodsList = !0;
                setLoading(!1)
            })
        }, 500)
    }
    let jumpPage = (item, type, index) => {
        if (type == 1) {
            if (index == 2) {
                axios.post("/JuTwitter/Page/freshUsers").then(res => {
                    if (res.code == 0) {
                        window.location.href = res.result;
                    }
                })
            } else {
                axios.post("/JuTwitter/Page/actLinkUser", {
                    act_link_id: item.id
                }).then(res => {
                    if (res.code === 0) {
                        window.location.href = res.result;
                    }
                })
            }

        } else if (type == 2) {
            axios.post("/JuTwitter/Page/goodsLinkUser", {
                goods_link_id: item.id
            }).then(res => {
                if (res.code === 0) {
                    window.location.href = res.result;
                }
            })
        } else if (type == 4) {
            axios.post(item).then(res => {
                if (res.code == 0) {
                    window.location.href = res.result;
                } else {
                    Toast.show({
                        content: res.msg
                    })
                }
            })
        } else {
            window.location.href = item;

        }
    }
    let handelPageScroll = () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        console.log(is_getGoodsList)
        if (scrollTop + windowHeight >= scrollHeight) {
            if (is_getGoodsList) {
                is_getGoodsList = !1;
                getGoodsList();
            }
        }
    }
    useEffect(() => {
        getShopInfo();
        window.addEventListener("scroll", handelPageScroll, false);
        return (() => {
            window.removeEventListener("scroll", handelPageScroll);
        })
    }, [])
    return (
        <>

            {
                info.shop_info ? (<div className={style.content}>
                    <div className={style.storeInfo}>
                        <img src={info.shop_info.store_logo} alt="" />
                        <div className={style.title}>
                            <div>{info.shop_info.store_name}</div>
                            <div>{info.shop_info.store_intro}</div>
                        </div>
                    </div>
                    <div className={style.service}>
                        <div className={style.left}>
                            <div>
                                <wx-open-launch-weapp
                                    id="launch-btn"
                                    username={info.wifi_appid.original_id}
                                    path={info.wifi_appid.path}
                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                >
                                    <script type="text/wxtag-template">
                                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                                    </script>
                                </wx-open-launch-weapp>
                                <img src={connect} alt="" />
                                <span>一键连接</span>
                            </div>
                            <div>一键安全连接WiFi</div>
                        </div>
                    </div>
                    <div className={style.storeGoodsBox}>
                        <wx-open-launch-weapp
                            id="launch-btn"
                            username={info.scan_code_appid.original_id}
                            path={info.scan_code_appid.path}
                            style={{ position: 'absolute', zIndex: 10, top: 0, left: 0, width: '100%', height: '100%' }}
                        >
                            <script type="text/wxtag-template">
                                <div style={{ position: 'absolute', zIndex: 10, top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                            </script>
                        </wx-open-launch-weapp>
                        <div className={style.storeGoods}>
                            <div className={style.top}>
                                <img src={info.shop_info.store_banner} alt="" />
                                <div className={style.address} style={{ backgroundImage: "url(" + require("../../../../image/back.png") + ")" }}>
                                    <div className={style.text}>
                                        {info.shop_info.store_site}
                                    </div>
                                </div>
                                <div className={style.discount}>
                                    <div>现场点餐</div>
                                    <div>享受下单优惠</div>
                                </div>
                            </div>
                            <div className={style.bot}>
                                <div className={style.goods}>
                                    {
                                        info.shop_goods_list.map((item, index) => {
                                            return (
                                                <div className={style.item} key={index}>
                                                    <img src={item.goods_picture} alt="" />
                                                    <div>{item.goods_name}</div>
                                                    <div><span>￥</span>{item.goods_price}</div>
                                                    <div>￥{item.goods_prime_cost}</div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                <div className={style.more}>
                                    进店查看更多
                                    <RightOutline color="#555" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.shopLogo}>
                        <div className={style.title}>连锁餐饮</div>
                        <Swiper loop>
                            {
                                info.chain_restaurant.map((item, index) => {
                                    return (
                                        <Swiper.Item key={index}>
                                            <div className={style.logoItem} key={index}>
                                                {
                                                    item.map((child, k) => {
                                                        return (
                                                            <img src={child.icon} alt="" key={k} onClick={() => jumpPage(child, 1)} />
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Swiper.Item>
                                    )
                                })
                            }
                        </Swiper>

                    </div>
                    <div className={style.storeLogo2}>
                        <div>
                            {
                                info.display_window.slice(0, 2).map((item, index) => {
                                    return (
                                        <img src={item.img} alt="" key={index} onClick={() => jumpPage(item, 1)} />
                                    )
                                })
                            }
                        </div>
                        <div>
                            {
                                info.display_window.slice(2, 5).map((item, index) => {
                                    return (
                                        <Image src={item.img} alt="" key={index} onClick={() => jumpPage(item, 1, index)} />
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={style.webpage}>
                        <div className={style.left}>
                            <Image src={require("../../../../image/webpage_img1.png")} onClick={() => jumpPage(info.bottom[0].link, 3)} />
                        </div>
                        <div className={style.right}>
                            <div className={style.top}>
                                <Image src={require("../../../../image/webpage_img2.png")} onClick={() => jumpPage(info.bottom[2].link, 3)} />
                            </div>
                            <div className={style.bot}>
                                <Image src={require("../../../../image/webpage_img3.png")} onClick={() => jumpPage(info.bottom[1].link, 3)} />
                                <Image src={require("../../../../image/webpage_img4.png")} onClick={() => jumpPage(info.bottom[2].link, 3)} />
                            </div>
                        </div>
                    </div>
                    <div className={style.coupon}>
                        <div className={style.title}>
                            <div>
                                <span>惠购券</span><span>大牌餐券 超多优惠</span>
                            </div>
                            <div onClick={() => jumpPage("/JuTwitter/Page/jdGoodsLinkUsers", 4)}>
                                <span>更多</span>
                                <RightOutline color="#999" />
                            </div>
                        </div>
                        <div className={style.couponGoods}>
                            {
                                info.hot_product_list.map((item, index) => {
                                    return (
                                        <div className={style.item} key={index} onClick={() => jumpPage(item, 1)}>
                                            <img src={item.hot_product_info.img} alt="" />
                                            <div>{item.hot_product_info.name}</div>
                                            <div><span>￥</span>{item.hot_product_info.sell_price}</div>
                                            <div>￥{item.hot_product_info.official_price}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={style.recommendGoods}>
                        <div className={style.title}>
                            <div>推荐商品</div>
                            <div>猜你喜欢</div>
                            <img src={icon11} alt="" />
                        </div>
                        <div className={style.list}>
                            {
                                goodsList.map((item, index) => {
                                    return (
                                        <div className={style.goodsItem} key={index} onClick={() => jumpPage(item, 2)}>
                                            <Image src={item.goods_list.goods_img} fit="fill" />
                                            <div className={style.goodsTitle}>{item.goods_list.short_name}</div>
                                            <div className={style.goodsPrice}>
                                                <span>￥</span><span>{item.goods_list.final_price}</span><span>￥{item.goods_list.goods_price}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            loading ? <div className={style.loading}>
                                <SpinLoading style={{ '--size': '16px', '--color': '#999' }} />
                                <span>上拉加载更多...</span>
                            </div> : null
                        }
                    </div>
                </div>) : null
            }
        </>
    )
}

export default Index