import React, { useState, useEffect } from 'react';
import axios from '../../util/axios';
import style from "./index.module.scss";
import { useNavigate } from 'react-router-dom';
import _ from "lodash";
function Life() {
    let navigate = useNavigate();
    let [state, setState] = useState({})
    let jumpPage = (item, type) => {
        if (type == 1) {
            axios.post("/JuTwitter/Page/actLinkUser", {
                act_link_id: item.id
            }).then(res => {
                if (res.code === 0) {
                    window.location.href = res.result;
                }
            })
        }
        if (type == 2) {
            axios.post(item).then(res => {
                if (res.code === 0) {
                    window.location.href = res.result;
                }
            })
        }
    }
    useEffect(() => {
        axios.post("/JuTwitter/Page/index").then(res => {
            if (res.code == 0) {
                let info = res.result;
                for (let i in info.chain_restaurant) info.chain_restaurant[i].icon = `https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/life/restaurant_img${Number(i) + 1}.png`
                setState(info);
            }
        })
    }, [])
    return (
        <>
            <div className={style.content}>
                <div className={style.hot} style={{ backgroundImage: "url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/life/back1.png)" }}>
                    <p>热门服务</p>
                    <div>
                        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/life/meituan.png" onClick={() => jumpPage(state.display_window[2], 1)} />
                        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/life/didi.png" onClick={() => jumpPage(state.display_window[3], 1)} />
                    </div>
                </div>
                <div className={style.restaurant}>
                    <p>精选连锁餐饮</p>
                    <div className={style.list}>
                        {
                            state.chain_restaurant?.map((item, index) => {
                                return (
                                    <div key={index} className={style.item} style={{ backgroundImage: "url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/life/back2.png)" }} onClick={() => jumpPage(item, 1)}>
                                        <img src={item.icon} alt="" />
                                        <div className={style.name}>{item.channel_name}</div>
                                        <div className={style.zhekou}>5折起</div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
                <div className={style.more}>
                    <p>更多服务</p>
                    <div className={style.service}>
                        <div className={style.left}>
                            <div className={style.title}>电商优选</div>
                            <img className={style.jd} src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/life/jd.png" onClick={() => {
                                localStorage.setItem("source", "jd");
                                localStorage.setItem("keyword", "");
                                navigate("/shopping")
                            }} />
                            <div className={style.mores}>
                                <div>
                                    <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/life/pdd.png" onClick={() => {
                                        localStorage.setItem("source", "pdd");
                                        localStorage.setItem("keyword", "");
                                        navigate("/shopping")
                                    }} />
                                    <p>多多推荐</p>
                                </div>
                                <div>
                                    <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/life/weipinhui.png" onClick={() => {
                                        localStorage.setItem("source", "vip");
                                        localStorage.setItem("keyword", "");
                                        navigate("/shopping")
                                    }} />
                                    <p>大牌精选</p>
                                </div>
                            </div>
                        </div>
                        <div className={style.right}>
                            <div className={style.title}>折扣优选</div>
                            <img className={style.youku} src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/life/youku.png" onClick={() => jumpPage("/JuTwitter/Page/CardShop", 2)} />
                            <div className={style.mores}>
                                <div>
                                    <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/life/youxuan.png" alt="" onClick={() => jumpPage("/JuTwitter/Page/BrandList", 2)} />
                                    <p>品牌优选</p>
                                </div>
                                <div>
                                    <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/life/coupon.png" alt="" onClick={() => jumpPage("/JuTwitter/Page/MealCoupon", 2)} />
                                    <p>大牌餐券</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Life