
import style from "./index.module.scss";
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
function Activated() {
    const navigate = useNavigate();
    const { state } = useLocation();
    let popstate = () => {
        navigate("/activated")
    }
    useEffect(() => {
        return (() => {
            window.removeEventListener('popstate', popstate, false)
        })
    }, [])
    return (
        <div className={style.activated}>
            <img src={require("../../image/activated.png")} alt="" />
            <p>{state}</p>
        </div>
    )
}

export default Activated;