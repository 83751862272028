import { Cascader } from 'antd-mobile'
import React, { useState, useEffect } from 'react';
import axios from '../../util/axios';
import _ from "lodash";
function City(props) {
    let [city, setCity] = useState([]);
    let [value, setValue] = useState([]);
    let getCity = (pid, is_children = 1) => {
        return new Promise(resolve => {
            axios.post("/JuTwitter/Area/index", pid ? { pid: pid } : null).then(res => {
                if (res.code == 0) {
                    let list = res.result.list;
                    for (let i in list) {
                        list[i].label = list[i].name;
                        list[i].value = list[i].id;
                        if (is_children) list[i].children = [];
                    }
                    if (list.length) resolve(list);
                }
            })
        })
    }
    let fetchOptionsForValue = async (v, level) => {
        let _city = _.cloneDeep(city);
        if (props.type == 1) {
            _city.map(item => {
                if (level == 1) {
                    if (item.id == v && !item.children.length) {
                        getCity(item.id, 0).then(res => {
                            item.children = res;
                            setCity(_city)
                        })
                    }
                }
            })
        }
        if (props.type == 2) {
            _city.map(item => {
                if (level == 1) {
                    if (item.id == v && !item.children.length) {
                        getCity(item.id).then(res => {
                            item.children = res;
                            setCity(_city)
                        })
                    }
                }
                if (level == 2 && item.children) {
                    item.children.map(row => {
                        if (row.id == v && !row.children.length) {
                            getCity(row.id, 0).then(res => {
                                row.children = res;
                                setCity(_city)
                            })
                        }
                    })

                }
            })
        }
    }
    let confirm = (value, extend) => {
        if (extend.isLeaf) {
            let name = "";
            for (let i in extend.items) {
                name += extend.items[i].name + '-'
            }
            setValue(value);
            props.confirm({
                ids: value,
                name: name.substring(0, name.length - 1)
            })
        }
    }
    useEffect(() => {
        getCity().then(res => {
            setCity(res)
        })
    }, [])
    return (
        <Cascader
            options={city}
            visible={props.visible}
            onCancel={() => props.cancel(!1)}
            value={value}
            onConfirm={(value, extend) => confirm(value, extend)}
            onSelect={(value) => {
                if (value.length == 1) {
                    fetchOptionsForValue(value[0], 1);
                }
                if (value.length == 2) {
                    fetchOptionsForValue(value[1], 2);
                }
            }}
        >
        </Cascader>
    )
}

export default City;