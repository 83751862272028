import './index.scss'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toast, Dialog } from 'antd-mobile'
import axios from '../../../util/axios'
import Empty from '../../../components/empty'
import _ from 'lodash'
function EnterpriseOrder() {
  const navigate = useNavigate()
  let [state, setState] = useState({
    tabs: [
      {
        name: '全部',
        state_type: 'all'
      },
      {
        name: '待付款',
        state_type: 'state_new'
      },
      {
        name: '待发货',
        state_type: 'state_pay'
      },
      {
        name: '待收货',
        state_type: 'state_send'
      },
      {
        name: '已完成',
        state_type: 'state_success'
      },
      {
        name: '已关闭',
        state_type: 'state_cancel'
      }
    ],
    list: [],
    page: 1,
    state_type: 'all',
    is_getList: !0,
    total_number: 0
  })
  let getOrderList = () => {
    axios
      .post('/JuTwitter/Order/list', {
        page: state.page,
        rows: 10,
        state_type: state.state_type
      })
      .then((res) => {
        if (res.code == 0) {
          let _state = _.cloneDeep(state)
          let list = res.result.list
          _state.total_number = res.result.total
          if (list.length) {
            _state.page++
            _state.is_getList = !0
            _state.list.push(...list)
          }
          setState(_state)
        } else {
          Toast.show({
            content: res.msg
          })
        }
      })
  }
  let cancelOrder = (e, id, index) => {
    e.stopPropagation()
    Dialog.confirm({
      content: `确认取消订单？`,
      onConfirm: () => {
        axios
          .post('/JuTwitter/Order/cancel', {
            id: id
          })
          .then((res) => {
            if (res.code == 0) {
              let _state = _.cloneDeep(state)
              if (state.state_type == 'all') {
                _state.list[index].state = 0
              } else {
                _state.list.splice(index, 1)
              }
              setState(_state)
              Toast.show({
                content: '订单已取消'
              })
            } else {
              Toast.show({
                content: res.msg
              })
            }
          })
      }
    })
  }
  let payOrder = (e, pay_sn) => {
    e.stopPropagation()
    axios
      .post('/JuTwitter/Buy/pay', {
        payment_channel: 'eyun_pay',
        pay_sn: pay_sn
      })
      .then((res) => {
        if (res.code == 0) {
          window.location.href = res.result.payUrl
        } else {
          Toast.show({
            content: res.msg || res.result
          })
        }
      })
  }
  let confirmOrder = (e, id) => {
    e.stopPropagation()
    Dialog.confirm({
      content: `是否确认收货？`,
      onConfirm: () => {
        axios
          .post('/JuTwitter/Order/confirmReceipt', {
            id: id
          })
          .then((res) => {
            if (res.code == 0) {
              setState({
                ...state,
                list: [],
                page: 1,
                is_getList: !0,
                state_type: state.state_type
              })
              Toast.show({
                content: '已收货'
              })
            } else {
              Toast.show({
                content: res.msg
              })
            }
          })
      }
    })
  }
  useEffect(() => {
    !state.is_getList && getOrderList()
  }, [state.is_getList])
  useEffect(() => {
    getOrderList()
  }, [state.state_type])
  return (
    <div
      className="enterprise-order"
      onScroll={(e) => {
        let { clientHeight, scrollHeight, scrollTop } = e.target
        if (scrollTop + clientHeight + 20 > scrollHeight) {
          if (state.is_getList) {
            setState({
              ...state,
              is_getList: !1
            })
          }
        }
      }}
    >
      <div className="tabs">
        {state.tabs.map((item, index) => {
          return (
            <div
              className={`${state.state_type == item.state_type ? 'active' : ''}`}
              key={index}
              onClick={() => {
                if (state.state_type != item.state_type) {
                  setState({
                    ...state,
                    list: [],
                    page: 1,
                    is_getList: !0,
                    state_type: item.state_type
                  })
                }
              }}
            >
              {item.name}
            </div>
          )
        })}
      </div>
      <div className="list">
        {state.list.map((item, index) => {
          return (
            <div className="item" key={index} onClick={() => navigate('/enterpriseOrderDetails', { state: item.id })}>
              <div className="order-top">
                <div
                  className="store"
                  onClick={(e) => {
                    e.stopPropagation()
                    navigate('/enterpriseShop', { state: item.shop_info.id })
                  }}
                >
                  <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/common/shop.png" />
                  {item.shop_info.shop_name}
                  <img src={require('../../../image/pointsMall/icon8.png')} alt="" />
                </div>
                <div className="status">{item.refund_info ? '维权中' : item.state_desc}</div>
              </div>
              {item.extend_order_goods.map((goods, y) => {
                return (
                  <div className="order-center" key={y}>
                    <img src={goods.goods_img} />
                    <div className="goods-con">
                      <div>
                        <span>{goods.goods_title}</span>
                        <span>￥{goods.goods_price}</span>
                      </div>
                      <div>
                        <span>{goods.goods_specs[0].attr_value}</span>
                        <span>x{goods.goods_num}</span>
                      </div>
                    </div>
                  </div>
                )
              })}
              <div className="order-footer">
                <div>{item.reduction_amount * 1 > 0 ? '优惠：-￥' + item.reduction_amount : ''}</div>
                <div>
                  共{item.extend_order_goods_num}件 实付
                  <span>￥{item.revise_amount > 0 ? item.revise_amount : (item.amount * 1).toFixed(2)}</span>
                  {Number(item.freight_fee) ? <span>（含运费{item.freight_fee}元）</span> : null}
                </div>
              </div>
              <div className="btn">
                {item.state == 10 ? (
                  <>
                    <div className="cancel" onClick={(e) => cancelOrder(e, item.id, index)}>
                      取消订单
                    </div>
                    <div className="pay" onClick={(e) => payOrder(e, item.pay_sn)}>
                      去付款
                    </div>
                  </>
                ) : item.state == 30 ? (
                  <div className="pay" onClick={(e) => confirmOrder(e, item.id)}>
                    确认收货
                  </div>
                ) : null}
              </div>
            </div>
          )
        })}
      </div>
      {state.list.length == 0 ? <Empty text="暂无订单" marginTop={180 / 37.5 + 'rem'} /> : null}
    </div>
  )
}

export default EnterpriseOrder
