import React, { useState, useEffect } from 'react';
import { Toast } from 'antd-mobile'
import style from "./index.module.scss";
import { handleLocationPath, getDateformat } from "../../util/utils";
import axios from '../../util/axios';
import { useNavigate } from 'react-router-dom';
import { Image,Swiper } from 'antd-mobile'
function PaySuccess() {
    const navigate = useNavigate();
    let [state, setState] = useState({});
    const { order_sn } = handleLocationPath();
    useEffect(() => {
        Toast.show({
            icon: 'loading',
            content: '加载中…',
            duration: 0
        })
        axios.post("/JuTwitter/PaySuccess/aliCallback", {
            order_sn: order_sn
        }).then(res => {
            Toast.clear();
            if (res.code == 0) {
                setState(res.result);
            }
        }).catch(() => Toast.clear())
    }, [])
    return (
        <>
            {
                state?.order_info ? <div className={style.content}>
                    <div className={style.pay_info} style={{ backgroundImage: "url(" + require("../../image/back4.png") + ")" }}>
                        <div>
                            <img src={state.store_info.store_logo} alt="" />
                        </div>
                        <div>支付成功</div>
                        <div>￥{state.order_info.money}</div>
                        <div>
                            <span>{state.store_info.store_name}</span><span>￥{state.order_info.money}</span>
                        </div>
                        <div>
                            <span>付款方式</span><span>支付宝</span>
                        </div>
                        <div>
                            <span>下单时间</span><span>{getDateformat(state.order_info.create_time)}</span>
                        </div>
                        <div>
                            <span>订单编号</span><span>{state.order_info.ordersn}</span>
                        </div>
                    </div>
                    <div className={style.banner}>
                        <div className={style.title}>到点支付优惠多多</div>
                        {
                            state.quanzhen_pay_success_activity_banners.length > 1 ? <Swiper style={{
                                '--border-radius': '6px',
                            }} loop>
                                {
                                    state.quanzhen_pay_success_activity_banners.map((item, index) => {
                                        return (
                                            <Swiper.Item key={index} onClick={()=>navigate('/')}>
                                                <Image src={item.image} fit="cover" />
                                            </Swiper.Item>
                                        )
                                    })
                                }
                            </Swiper> : <Swiper style={{
                                '--border-radius': '6px',
                            }} indicator={() => null}>
                                {
                                    state.quanzhen_pay_success_activity_banners.map((item, index) => {
                                        return (
                                            <Swiper.Item key={index} onClick={()=>navigate('/')}>
                                                <Image src={item.image} fit="cover" />
                                            </Swiper.Item>
                                        )
                                    })
                                }
                            </Swiper>
                        }
                    </div>
                </div> : null
            }
        </>
    )
}

export default PaySuccess;