import "./index.scss";
import React, { Component } from 'react';
import { Tabs, Image, Toast, SpinLoading, SearchBar } from 'antd-mobile'
import axios from "../../util/axios";
class Shopping extends Component {
    constructor(props) {
        super(props)
        this.state = {
            platform: [{
                name: '京东',
                title: '好券商品',
                type: 'jd',
                logo: require("../../image/jd.png")
            }, {
                name: '拼多多',
                title: '多多推荐',
                type: 'pdd',
                logo: require("../../image/pdd.png")
            }, {
                name: '唯品会',
                title: '大牌精选',
                type: 'vip',
                logo: require("../../image/vip.png")
            }],
            platform_logo: require("../../image/jd.png"),
            loading: !0,
            goodsList: [],
            goodsClassify: [],
            page: 1,
            rows: 10,
            cat: 1,
            source: localStorage.getItem("source"),
            keyword: localStorage.getItem("keyword"),
            is_load: !0,
        }
    }
    render() {
        return (
            <div className="content-platform">
                <div className="search">
                    <SearchBar placeholder='请输入商品名称' value={this.state.keyword} onSearch={() => this.search()} onChange={(e) => this.setState({ keyword: e })} />
                </div>
                <div className="box">
                    <div className="platform">
                        {
                            this.state.platform.map((item, index) => {
                                return (
                                    <div className="item" key={index} onClick={() => this.selectPlatform(item)}>
                                        <div>{item.name}</div>
                                        <div className={`${this.state.source == item.type ? 'active' : ''}`}>{item.title}</div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className="goods_type">
                        <Tabs style={{ "--active-title-color": "#333", "--active-line-color": "#333" }} activeKey={this.state.cat} onChange={(e) => this.selectGoodsClassify(e)}>
                            {
                                this.state.goodsClassify.map((item) => {
                                    return (
                                        <Tabs.Tab title={item.name} key={item.id}></Tabs.Tab>
                                    )
                                })
                            }
                        </Tabs>
                    </div>
                    <div className="goods-list">
                        {
                            this.state.goodsList.map((item, index) => {
                                return (
                                    <div className="item" key={index} onClick={() => this.goGoodsDetail(item.goodsId)}>
                                        <Image src={item.goodsThumbUrl} />
                                        <div className="goods-info">
                                            <div className="title">
                                                <img src={this.state.platform_logo} alt="" />
                                                {item.goodsName}
                                            </div>
                                            {
                                                item.discount ? <div className="coupon" style={{ backgroundImage: "url(" + require("../../image/icon5.png") + ")" }}>
                                                    {item.discount}元券
                                                </div> : null
                                            }

                                            <div className="price">
                                                <span>券后￥</span><span>{item.price}</span><span>￥{item.marketPrice}</span>
                                            </div>
                                            <div className="storeName-sales">
                                                <div className="storeName">
                                                    <Image src={require("../../image/icon6.png")} />
                                                    <span>{item.shopName}</span>
                                                </div>
                                                <div>销量：{item.sales_tip}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="loading">
                    {
                        this.state.loading ? <SpinLoading style={{ '--size': '16px', '--color': '#999' }} /> : null
                    }
                    <span>{this.state.loading ? '上拉加载更多...' : '没有更多了...'} </span>
                </div>
            </div>
        )
    }
    componentDidMount() {
        document.title = "购物";
        this.getGoodsClassify();
        this.search();
        window.addEventListener("scroll", this.handelPageScroll, false);
    }
    selectPlatform = (item) => {
        if (item.type != this.state.source) {
            this.setState({
                platform_logo: item.logo,
                keyword: '',
                page: 1,
                cat: 1,
                is_load: !0,
                loading: !0,
                source: item.type,
                goodsList: []
            }, () => {
                this.getGoodsClassify();
                this.getGoodsList();
            })
        }
    }
    selectGoodsClassify = (e) => {
        this.setState({
            page: 1,
            keyword: '',
            loading: !0,
            is_load: !0,
            cat: e,
            goodsList: []
        }, () => {
            this.getGoodsList();
        })
    }
    getGoodsClassify = () => {
        axios.post("/JuTwitter/Page/goods_category", {
            source: this.state.source
        }).then(res => {
            if (res.code == 0) {
                let data = [];
                for (let i in res.result.data) {
                    let obj = {
                        id: i,
                        name: res.result.data[i]
                    }
                    data.push(obj);
                }
                this.setState({
                    goodsClassify: data
                })
            }
        })
    }
    getGoodsList = () => {
        axios.post(this.state.keyword ? "/JuTwitter/Page/search" : "/JuTwitter/Page/query_goods", {
            source: this.state.source,
            cat: this.state.cat,
            page: this.state.page,
            rows: this.state.rows,
            keyword: this.state.keyword
        }).then(res => {
            if (res.code == 0) {
                let data = res.result.data;
                this.setState({
                    is_load: !0,
                })
                if (data.length) {
                    this.state.goodsList.push(...data);
                    this.setState({
                        page: this.state.page + 1,
                        goodsList: this.state.goodsList
                    }, () => {
                        if (this.state.goodsList.length >= res.result.total_results) {
                            this.setState({
                                loading: !1,
                                is_load: !1
                            })
                        }
                    })

                } else {
                    this.setState({
                        loading: !1,
                        is_load: !1
                    })
                }
            } else {
                Toast.show({
                    content: res.msg
                })
            }
        })
    }

    goGoodsDetail = (goodsId) => {
        axios.post("/JuTwitter/Page/convert", {
            goodsId,
            source: this.state.source
        }).then(res => {
            if (res.code == 0) {
                window.location.href = res.result.data.url;
            } else {
                Toast.show({
                    content: res.msg
                })
            }
        })
    }
    search = () => {
        this.setState({
            page: 1,
            is_load: !0,
            loading: !0,
            goodsList: []
        }, () => {
            if (this.state.keyword) {
                axios.post("/JuTwitter/Page/search", this.state.keyword && {
                    source: this.state.source,
                    keyword: this.state.keyword
                }).then(res => {
                    if (res.code == 0) {
                        let data = res.result.data;
                        this.setState({
                            is_load: !0
                        })
                        if (data.length) {
                            this.setState({
                                page: this.state.page + 1,
                                goodsList: data
                            }, () => {
                                if (this.state.goodsList.length >= res.result.total_results) {
                                    this.setState({
                                        loading: !1,
                                        is_load: !1
                                    })
                                }
                            })

                        } else {
                            this.setState({
                                loading: !1,
                                is_load: !1,
                                goodsList: []
                            })
                        }
                    } else {
                        Toast.show({
                            content: res.msg
                        })
                    }
                })
            } else {
                this.getGoodsList();
            }
        })
    }

    handelPageScroll = () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + windowHeight + 20 >= scrollHeight) {
            if (this.state.is_load) {
                this.setState({
                    is_load: !1,
                }, () => {
                    this.getGoodsList();
                })
            }
        }
    }
}

export default Shopping;

