import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { login } from '../../util/utils';
import _ from "lodash";
import style from "./index.module.scss";
import icon1 from "../../image/icon1.png";
import icon2 from "../../image/icon2.png";
import icon3 from "../../image/icon3.png";
import icon4 from "../../image/icon4.png";
import icon17 from "../../image/icon17.png";
import icon18 from "../../image/icon18.png";
import icon19 from "../../image/icon19.png";
import icon20 from "../../image/icon20.png";
import icon21 from "../../image/icon21.png";
import icon22 from "../../image/icon22.png";
import icon23 from "../../image/icon23.png";
import icon31 from "../../image/icon31.png";
import icon32 from "../../image/icon32.png";
function Footer() {
  const utoken = localStorage.getItem("utoken");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let [tabbar, setTabbar] = useState([
    {
      name: '微码惠',
      icon: icon2,
      active_icon: icon21,
      selected: !0,
      path: '/'
    },
    {
      name:'企业列表',
      icon: icon32,
      active_icon: icon31,
      selected: !0,
      path:'/enterpriseList'
    },
    // {
    //   name:'种草社区',
    //   icon: icon19,
    //   active_icon: icon20,
    //   selected: !0,
    //   path:'/community'
    // },
    {
      name:'兑好礼',
      icon: icon17,
      active_icon: icon22,
      selected: !0,
      path:'/pointsMall'
    },
    {
      name: '个人中心',
      icon: icon4,
      active_icon: icon23,
      selected: !1,
      path: '/my'
    }
  ])
  let go = (index) => {
    if (!utoken) return login();
    let data = _.cloneDeep(tabbar);
    if (data[index].path) {
      data.map(item => item.selected = !1);
      data[index].selected = !0;
      setTabbar(data);
      navigate(data[index].path)
    }
  }
  useEffect(()=>{
    let data = _.cloneDeep(tabbar);
    data.map(item => {
      if (item.path == pathname) {
        item.selected = !0;
      } else {
        item.selected = !1;
      }
    })
    setTabbar(data);
  },[pathname])
  return (
    <>
      <div className={style.footer}>
        {
          tabbar.map((item, index) => {
            return (
              <div style={{ color: item.selected ? '#FF1607' : '' }} key={index} onClick={() => go(index)}>
                <img src={item.selected ? item.active_icon : item.icon} />
                <span>{item.name}</span>
              </div>
            )
          })
        }
      </div>
    </>
  )
}

export default Footer;