import "./index.scss";
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../../util/axios";
import _ from "lodash";
function EnterpriseShop() {
    const navigate = useNavigate();
    const location = useLocation();
    let [state, setState] = useState({
        list: [],
        page: 1,
        sortType: 0,
        company_id: location.state,
        is_getList: !0,
        total_number: 0
    })
    let [info, setInfo] = useState({});
    let [showHand, setShowHand] = useState(!1);
    let getGoodsList = () => {
        axios.post("/JuTwitter/CompanyGoods/cpsList", {
            company_id: state.company_id,
            page: state.page,
            sortType: state.sortType
        }).then(res => {
            if (res.code == 0) {
                let _state = _.cloneDeep(state);
                let list = res.result.list;
                _state.total_number = res.result.total_number;
                if (list.length) {
                    _state.page++;
                    _state.is_getList = !0;
                    _state.list.push(...list);
                }
                setState(_state);
            }
        })
    }
    let selectType = (type) => {
        if (type != state.sortType) {
            setState({
                ...state,
                list: [],
                page: 1,
                sortType: type
            })
        }
    }
    useEffect(() => {
        getGoodsList();
    }, [state.sortType])
    useEffect(() => {
        axios.post("/JuTwitter/Company/info", {
            id: location.state
        }).then(res => {
            if (res.code == 0) {
                setInfo(res.result);
            }
        })
    }, [])
    return (
        <div className="enterprise-shop">
            {
                showHand ? <img className="hand" src={require("../../../image/community/icon14.png")} alt="" /> : null
            }
            <div className="info">
                <img className="logo" src={info.shop_logo} />
                <div className="name">
                    <div onClick={() => navigate("/enterpriseInfo", { state: state.company_id })}>
                        {info.shop_name}
                        <img src={require("../../../image/icon30.png")} />
                    </div>
                    <img className="authentication" src={require("../../../image/icon29.png")} />
                </div>
                <div className="share" onClick={() => {
                    setShowHand(!0);
                    setTimeout(() => {
                        setShowHand(!1);
                    }, 8000)
                }}>分享</div>
            </div>
            <div className="goods">
                <div className="type">
                    <div className={`${state.sortType == 0 ? 'active' : ''}`} onClick={() => selectType(0)}>默认</div>
                    <div className={`${state.sortType == 1 ? 'active' : ''}`} onClick={() => selectType(1)}>销量</div>
                </div>
                <div className="list">
                    {
                        state.list.map((item, index) => {
                            return (
                                <div className="item" key={index} onClick={() => navigate("/enterpriseGoodsDetails", { state: item.id })}>
                                    <img src={JSON.parse(item.head_imgs)[0]} alt="" />
                                    <div className="title">{item.title}</div>
                                    <div className="price">
                                        <span>{item.sale_price}</span>
                                        <span>已售{item.sales}件</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    state.total_number == state.list.length ? <div className="no">— 没有更多啦 —</div> : null
                }
            </div>
        </div>
    )
}

export default EnterpriseShop;