import style from "./index.module.scss";
import React, { useState, useEffect } from 'react';
import axios from "../../util/axios";
import { useNavigate } from "react-router-dom";
import { Input, Toast } from 'antd-mobile'
function AddCard() {
    const navigate = useNavigate();
    let [state, setState] = useState({
        bank_card: '',
        bank_name: '',
        bank_info: ''
    })
    let getCardInfo = () => {
        axios.post("/JuTwitter/User/getBankCard").then(res => {
            if (res.code == 0) {
                if (res.result) {
                    document.title = "编辑银行卡";
                } else {
                    document.title = "添加银行卡";
                }
            }
        })
    }
    let submit = () => {
        try {
            if (!state.bank_name) throw "请输入姓名";
            if (!state.bank_card) throw "请输入银行卡号";
            if (!state.bank_info) throw "请输入开户行名称";
            axios.post("/JuTwitter/User/editBankCard",state).then(res => {
                if (res.code === 0) {
                    Toast.show({
                        content: "添加成功",
                        icon: "success"
                    })
                    navigate(-1);
                } else {
                    Toast.show({
                        content: res.msg
                    })
                }
            })
        } catch (e) {
            Toast.show({
                content: e
            })
        }
    }
    useEffect(() => {
        getCardInfo();
    }, [])
    return (
        <div className={style.content}>
            <div className={style.form_info}>
                <div className={style.title}>姓名</div>
                <Input placeholder="请输入姓名" value={state.bank_name} onChange={e => setState({ ...state, bank_name: e })} />
            </div>
            <div className={style.form_info}>
                <div className={style.title}>银行卡号 <span>（仅支持储蓄卡）</span></div>
                <Input placeholder="请输入银行卡号" value={state.bank_card} onChange={e => setState({ ...state, bank_card: e })} />
            </div>
            <div className={style.form_info}>
                <div className={style.title}>开户行名称</div>
                <Input placeholder="请输入开户行名称" value={state.bank_info} onChange={e => setState({ ...state, bank_info: e })} />
            </div>
            <div className={style.btn} onClick={() => submit()}>确认</div>
        </div>
    )
}

export default AddCard;