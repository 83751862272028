import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Footer from './components/footer'
import { login, handleLocationPath } from './util/utils'
import axios from './util/axios'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Toast } from 'antd-mobile'
import routers from './util/routers'
function DomTitle({ item }) {
  document.title = item.title
  return <item.component />
}
function App() {
  const equipment = process.env.NODE_ENV == 'production' ? 'micromessenger' : null
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const tabbarPath = ['/', '/pointsMall', '/enterpriseList', '/my']
  const [showTabbar, setShowTabbar] = useState(false)
  const utoken = localStorage.getItem('utoken')
  const { type, sn, timestamp } = handleLocationPath()
  let is_showTabbar = useSelector((state) => state.showTabbar)
  let browser = navigator.userAgent.toLowerCase()
  //获取商家二维码激活状态
  let getActivation = () => {
    axios.post('/JuTwitter/Page/is_activation').then((res) => {
      if (res.code == 0) {
        if (!res.result) {
          if (type) {
            axios.post('/JuTwitter/ChannelsCompany/info').then((res) => {
              if (res.code == 0) {
                res.result ? navigate('/enterpriseSettlementInfo', { replace: true }) : navigate('/enterpriseSettlementIndex', { replace: true })
              } else if (res.code == -4) {
                navigate('/activated', {
                  replace: true,
                  state: res.msg
                })
              } else {
                Toast.show({
                  icon: 'fail',
                  content: res.msg
                })
              }
            })
          } else {
            axios.post('/JuTwitter/StoreSettled/info').then((res) => {
              if (res.code == 0) {
                if (res.result) {
                  navigate('/reviewProgress', {
                    replace: true
                  })
                } else {
                  navigate('/activationApply', {
                    replace: true
                  })
                }
              } else if (res.code == -2) {
                navigate('/activated', {
                  replace: true,
                  state: '已经被其他商户注册，请您联系服务商'
                })
              } else if (res.code == 30001) {
                navigate('/activated', {
                  replace: true,
                  state: '您已经申请商户，不可重复申请'
                })
              } else {
                Toast.show({
                  icon: 'fail',
                  content: res.msg
                })
              }
            })
          }
        }
      }
    })
  }
  useEffect(() => {
    setShowTabbar(tabbarPath.includes(pathname))
    if (browser.match(/Alipay/i) == 'alipay') return
    if (browser.match(/MicroMessenger/i) == equipment) {
      dispatch({ type: 'setShowTabbar', data: !1 })
    }
  }, [pathname])
  useEffect(() => {
    if (sn) localStorage.setItem('sn', sn)
    if (timestamp) {
      if (browser.match(/MicroMessenger/i) == equipment) {
        if (utoken) getActivation()
        if (!utoken) login()
      }
    } else {
      let str = window.location.href.indexOf('?') == -1 ? '?' : ''
      let timeurl = window.location.href + str + '&timestamp=' + new Date().getTime()
      window.location.replace(timeurl)
    }
  }, [])
  return (
    <>
      <Routes>
        {routers.map((item, index) => {
          return <Route path={item.path} element={<DomTitle item={item} />} key={index} />
        })}
      </Routes>
      {showTabbar && !is_showTabbar ? <Footer></Footer> : null}
    </>
  )
}

export default App
