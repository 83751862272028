import style from './index.module.scss'
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from '../../../util/axios'
import { getDateformat } from '../../../util/utils'
import { Toast } from 'antd-mobile'
import { copyText } from '../../../util/utils'

function AfterSales() {
  const location = useLocation()
  const navigate = useNavigate()
  let [info, setInfo] = useState(null)
  let getInfo = () => {
    axios
      .post('/JuTwitter/Orderrefund/info', {
        id: location.state
      })
      .then((res) => {
        if (res.code == 0) {
          setInfo(res.result.info)
        }
      })
  }
  useEffect(() => {
    getInfo()
  }, [])
  return (
    <>
      {info ? (
        <div className={style.content}>
          <div className={style.orderContent}>
            <img className={style.goodsIcon} src={info.goods_img} />
            <div className={style.goodsDetails}>
              <div className={style.titleNum}>
                <div className={style.goodsTitle}>{info.goods_title}</div>
                <div className={style.goodsNum}>x{info.goods_num}</div>
              </div>
              {info.goods_spec ? <div className={style.specifications}>{info.goods_spec[0].attr_value}</div> : null}
            </div>
            {info.shipper_info && !info.tracking_time ? (
              <div
                className={style.p_but}
                onClick={() => {
                  navigate('/returnInfomation', { state: info })
                }}
              >
                填写物流
              </div>
            ) : null}
            {info.tracking_time ? (
              <div
                className={style.p_but}
                onClick={() => {
                  navigate('/logistics', { state: { info: info } })
                }}
              >
                查看物流
              </div>
            ) : null}
          </div>
          <div className={style.refundMethod}>
            <div className={style.pattern}>
              <div className={style.patternLeft}>退款方式</div>
              <div>{info.refund_type == 1 ? '仅退款' : '退货退款 '}</div>
            </div>
            <div className={style.pattern}>
              <div className={style.patternLeft}>退款金额</div>
              <div className={style.redText}>￥{info.refund_amount}</div>
            </div>
            <div className={style.pattern}>
              <div className={style.patternLeft}>处理进度</div>
              <div>{info.state_desc}</div>
            </div>
            {info.shipper_info ? (
              <>
                <div className={style.pattern}>
                  <div className={style.patternLeft}>退货地址</div>
                  <div
                    className={style.redText}
                    onClick={(e) => {
                      copyText(e, info.shipper_info.name + info.shipper_info.contact_number + info.shipper_info.combine_detail + info.shipper_info.address).then(() => {
                        Toast.show({
                          content: '已复制'
                        })
                      })
                    }}
                  >
                    复制
                  </div>
                </div>
                <div className={style.shipperInfo}>
                  <div className={style.refundText}>
                    <div>
                      {info.shipper_info.name} {info.shipper_info.contact_number}
                    </div>
                    <div>
                      {info.shipper_info.combine_detail} {info.shipper_info.address}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className={style.supplement}>
            <div className={style.supplementTop}>补充描述和凭证</div>
            <div className={style.supplementText}>{info.user_reason}</div>
            {info.user_images ? (
              <div className={style.refundIcon}>
                {info.user_images.map((item, index) => {
                  return <img src={item} className={style.p_image} key={index} />
                })}
              </div>
            ) : null}
          </div>
          <div className={style.orderDetail}>
            <div className={style.pattern}>
              <div>订单号</div>
              <div>{info.refund_sn}</div>
            </div>
            <div className={style.pattern}>
              <div>下单时间</div>
              <div>{getDateformat(info.create_order_time)}</div>
            </div>
            <div className={style.pattern}>
              <div>申请售后</div>
              <div>{getDateformat(info.create_time)}</div>
            </div>
          </div>
          {info.handle_state == 0 ? (
            <div className={style.footer}>
              <div
                className={style.bottomButton}
                onClick={() => {
                  axios
                    .post('/JuTwitter/Orderrefund/revoke', {
                      id: info.id
                    })
                    .then((res) => {
                      if (res.code == 0) {
                        Toast.show('撤销成功')
                        setTimeout(() => {
                          window.history.go(-1)
                        }, 2000)
                      }
                    })
                }}
              >
                撤销申请
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  )
}

export default AfterSales
