import './index.scss'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import axios from '../../util/axios'
import { copyText } from '../../util/utils'
import { Toast } from 'antd-mobile'
function Logistics() {
  const location = useLocation()
  let [info, setInfo] = useState({
    data: []
  })

  let filterTime = (value) => {
    let specificDate = value.split(' ')
    let p_time = specificDate[0].split('-')
    let month = p_time[1]
    let day = p_time[2]
    return month + '/' + day
  }
  let p_filterTime = (value) => {
    let specificDate = value.split(' ')
    let p_time = specificDate[1].split(':')
    let hour = p_time[0]
    let minute = p_time[1]
    return hour + ':' + minute
  }
  useEffect(() => {
    if (location.state.info) {
      let data = { ...location.state.info.logisticsQuery }
      data.company_name = location.state.info.tracking_company
      setInfo(data)
    } else {
      axios
        .post(location.state.type == 1 ? '/JuTwitter/IntegralOrder/logisticsQuery' : '/JuTwitter/Order/logisticsQuery', {
          id: location.state.id
        })
        .then((res) => {
          if (res.code == 0) {
            console.log(res)
            setInfo(res.result.info)
          }
        })
    }
  }, [])
  return (
    <div className="logistics-info">
      <div className="header">
        <div>
          物流公司：<span className="headerRight">{info.company_name}</span>
        </div>
        <div className="order-no">
          <div>
            物流单号：<span className="headerRight">{info.nu}</span>
          </div>
          <img className="copyIcon" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/p_copy.png" />
          <div
            className="copyText"
            onClick={(e) => {
              copyText(e, info.nu).then(() => {
                Toast.show({
                  content: '内容已复制'
                })
              })
            }}
          >
            复制
          </div>
        </div>
      </div>
      {info.data.map((item, index) => {
        return (
          <div className="content" key={index}>
            <div className="contentLeft">
              <div className="p_date">{filterTime(item.ftime)}</div>
              <div className="p_time">{p_filterTime(item.ftime)}</div>
            </div>
            <div className="icons">
              {item.status == '签收' ? (
                <div className="p_icon">
                  <img className="icon" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/cartIcon.png" />
                </div>
              ) : null}
              {item.status == '派件' ? <img className="p_icons" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/dispatch.png" /> : null}
              {item.status == '在途' || item.status == '揽收' ? <img className="p_icons" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/intransit.png" /> : null}
              {info.data.length - 1 !== index ? <div className="line" v-if="logisticsData.length - 1 !== index"></div> : null}
            </div>
            <div className="contentRight">
              <div className="statusText">{item.status}</div>
              <div className="context">{item.context}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Logistics
