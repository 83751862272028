
import { useLocation } from "react-router-dom";
import "./index.scss";
function DrawLotteryRules() {
    const location = useLocation();
    return (
        <div className="drawLottery-rules">
            <div dangerouslySetInnerHTML={{ __html: location.state }}></div>
        </div>
    )
}

export default DrawLotteryRules;