import "./index.scss";
import React, { useState, useEffect } from 'react';
import { Modal, Toast } from 'antd-mobile';
import axios from "../../../util/axios";
import { getDateformat } from "../../../util/utils";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Empty from "../../../components/empty";
function IntegalOrder() {
    const navigate = useNavigate();
    let [state, setState] = useState({
        tabs: [{
            name: '全部',
            status: 0
        }, {
            name: '待付款',
            status: 10
        }, {
            name: '待发货',
            status: 20
        }, {
            name: '待收货',
            status: 30
        }, {
            name: '已完成',
            status: 40
        }, {
            name: '已关闭',
            status: -10
        }],
        page: 1,
        status: 0,
        total_number: 0
    })
    let is_getList = !0;
    let [list, setList] = useState([]);
    let selectStatus = (status) => {
        if (status != state.status) {
            setState({
                ...state,
                status: status,
                page: 1
            })
            setList([]);
        }
    }
    let getList = () => {
        axios.post("/JuTwitter/IntegralOrder/list", {
            rows: 10,
            page: state.page,
            status: state.status
        }).then(res => {
            if (res.code == 0) {
                console.log(res)
                let data = res.result.list;
                let lists = _.cloneDeep(list);
                if (data.length) {
                    lists.push(...data);
                    setList(lists);
                    setState({
                        ...state,
                        page: state.page + 1,
                        total_number: res.result.total_number
                    })
                }
            }
        })
    }
    let cancleOrder = async (id, index) => {
        let _list = _.cloneDeep(list);
        let result = await Modal.confirm({
            content: '确认取消订单？'
        })
        if (result) {
            axios.post("/JuTwitter/IntegralOrder/updateStatus", {
                id: id,
                status: -10
            }).then(res => {
                if (res.code == 0) {
                    if (state.status == 0) {
                        _list[index].status = -10;
                    } else {
                        _list.splice(index, 1);
                    }
                    setList(_list)
                    Toast.show({
                        content: "订单已取消",
                        icon: "success"
                    })
                } else {
                    Toast.show({
                        content: res.msg
                    })
                }
            })
        }
    }
    let confimReceipt = async (id, index) => {
        let _list = _.cloneDeep(list);
        let result = await Modal.confirm({
            content: '确认是否收货？'
        })
        if (result) {
            axios.post("/JuTwitter/IntegralOrder/updateStatus", {
                id: id,
                status: 40
            }).then(res => {
                if (res.code == 0) {
                    if (state.status == 0) {
                        _list[index].status = 40;
                    } else {
                        _list.splice(index, 1);
                    }
                    setList(_list)
                    Toast.show({
                        content: "订单已取消",
                        icon: "success"
                    })
                } else {
                    Toast.show({
                        content: res.msg
                    })
                }
            })
        }
    }
    let orderPay = (id) => {
        axios.post("/JuTwitter/IntegralOrder/pay", {
            order_id: id
        }).then(res => {
            if (res.code == 0) {
                window.location.href = res.result.payUrl;
            } else {
                Toast.show({
                    content: res.msg
                })
            }
        })
    }
    useEffect(() => {
        getList();
    }, [state.status])
    useEffect(() => {
        document.title = "积分订单";
    }, [])

    return (
        <div className="integral-order" onScroll={(e) => {
            let { clientHeight, scrollHeight, scrollTop } = e.target;
            if (scrollTop + clientHeight + 20 > scrollHeight) {
                if (is_getList) {
                    is_getList = !1;
                    getList();
                }
            }
        }}>
            <div className="tabs">
                {
                    state.tabs.map((item, index) => {
                        return (
                            <div className={`${item.status == state.status ? 'active' : ''}`} key={index} onClick={() => selectStatus(item.status)}>{item.name}</div>
                        )
                    })
                }
            </div>
            <div className="list">
                {
                    list.map((item, index) => {
                        return (
                            <div className="item" key={index}>
                                <div className="order-no">
                                    <span>订单编号：{item.order_no}</span>
                                    <span>{item.status == 10 ? '待支付' : item.status == 20 ? '待发货' : item.status == 30 ? '待收货' : item.status == 40 ? '已完成' : '已关闭'}</span>
                                </div>
                                <div className="goods-info">
                                    <img src={item.goods_cover_picture} alt="" />
                                    <div className="goods-con">
                                        <div className="goods-title">{item.goods_name}</div>
                                        <div className="goods-price">
                                            <div>{item.integral_amount}积分 {Number(item.price) ? <span>+￥{item.price}</span> : null}</div>
                                            <div>x1</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="time">兑换时间：{getDateformat(item.create_time)}</div>
                                <div className="total-price">
                                    <span>共1件 实付</span>
                                    <span>{item.integral_amount}积分 {Number(item.amount) ? '+￥' + item.amount : null}</span>
                                    {
                                        Number(item.freight) ? <span>（含运费{item.freight}元）</span> : null
                                    }
                                </div>
                                <div className="btn">
                                    {
                                        item.status == 10 ? (
                                            <>
                                                <div className="cancel" onClick={() => cancleOrder(item.id, index)}>取消订单</div>
                                                <div className="pay" onClick={() => orderPay(item.id)}>立即支付</div>

                                            </>
                                        ) : null
                                    }
                                    {
                                        item.status == 30 || item.status == 40 ? <div className="logistics" onClick={() => navigate("/logistics", { state: {id:item.id,type:1} })}>查看物流</div> : null
                                    }
                                    {
                                        item.status == 30 ? <div className="confirm" onClick={() => confimReceipt(item.id, index)}>确认收货</div> : null
                                    }
                                </div>
                            </div>
                        )
                    })
                }
                {
                    list.length == 0 ? <Empty text="暂无订单" marginTop={180 / 37.5 + 'rem'} /> : null
                }
                {
                    state.total_number == list.length && list.length != 0 ? <div className="no">- 没有更多啦 -</div> : null
                }
            </div>
        </div>
    )
}

export default IntegalOrder;