import './index.scss'
import React, { useState, useEffect } from 'react'
import { Input, TextArea, Cascader, Toast, Image, Radio, Space } from 'antd-mobile'
import { CloseCircleFill, RightOutline } from 'antd-mobile-icons'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from '../../../util/axios'
import City from '../../../components/city'
import _, { union } from 'lodash'
function EnterpriseSettlementApply() {
  const location = useLocation()
  const navigate = useNavigate()
  let [state, setState] = useState({
    type: location.state,
    speed: 1,
    grade: 1,
    bank_type: 1,
    company_name: '',
    name: '',
    identity_card_front: '',
    identity_card_back: '',
    mobile: '',
    id_number: '',
    email: '',
    business_license: '',
    contract_picture: [],
    shop_logo: '',
    shop_Introduction: '',
    shop_banner: [],
    bank_province_id: '',
    bank_city_id: '',
    province_id: '',
    city_id: '',
    area_id: '',
    shop_name: '',
    shop_address: '',
    city_name: '',
    area_name: '',
    category_id: '',
    cate_name: '',
    qualification: [],
    is_new_version: 0
  })
  let [visible, setVisible] = useState(false)
  let [visible1, setVisible1] = useState(false)
  let [visible2, setVisible2] = useState(false)
  let [cate, setCate] = useState([])
  let [cateVal, setCateVal] = useState([])
  let businessCate = () => {
    axios.post('/JuTwitter/ChannelsCompany/categoryList').then((res) => {
      if (res.code == 0) {
        let list = res.result
        for (let i in list) {
          list[i].label = list[i].name
          list[i].value = list[i].name
          for (let y in list[i]._child) {
            list[i]._child[y].label = list[i]._child[y].name
            list[i]._child[y].value = list[i]._child[y].name
            for (let k in list[i]._child[y]._child) {
              list[i]._child[y]._child[k].label = list[i]._child[y]._child[k].name
              list[i]._child[y]._child[k].value = list[i]._child[y]._child[k].name
            }
            list[i]._child[y].children = list[i]._child[y]._child
          }
          list[i].children = list[i]._child
        }
        setCate(list)
      }
    })
  }
  let getFiles = async (e, type) => {
    let files = e.target.files
    let formData = new FormData()
    let _state = _.cloneDeep(state)
    formData.append('sn', localStorage.getItem('sn'))
    formData.append('utoken', localStorage.getItem('utoken'))
    for (let index = 0; index < files.length; index++) {
      formData.append('file', files[index])
      let result = await upFiles(formData)
      if (type == 1) _state.identity_card_front = result.path
      if (type == 2) _state.identity_card_back = result.path
      if (type == 3) _state.contract_picture.push(result.path)
      if (type == 4) {
        await axios
          .post('/JuTwitter/VerifyBusinessLicense/verify', {
            business_license: result.path
          })
          .then((res) => {
            if (res.code == 0) {
              console.log(res)
              if (res.result.ErrorCode == 0) {
                _state.business_license = result.path
              } else {
                Toast.show({
                  content: '请上传正确的营业执照'
                })
              }
            } else {
              Toast.show({
                content: res.msg
              })
            }
          })
      }
      if (type == 5) _state.shop_logo = result.path
      if (type == 6) _state.qualification.push(result.path)
      if (type == 7) _state.shop_banner.push(result.path)
    }
    setState(_state)
  }
  let upFiles = (formData) => {
    return new Promise((resolve) => {
      Toast.show({
        duration: 0,
        icon: 'loading',
        content: '上传中...'
      })
      axios.uploadFile('/JuTwitter/Upload/index', formData).then((res) => {
        Toast.clear()
        if (res.code === 0) {
          resolve(res.result)
        } else {
          Toast.show({
            content: res.msg
          })
        }
      })
    })
  }
  let getInfo = () => {
    axios.post('/JuTwitter/ChannelsCompany/info').then((res) => {
      if (res.code == 0) {
        res.result.contract_picture = JSON.parse(res.result.contract_picture)
        if (res.result.qualification) {
          res.result.qualification = JSON.parse(res.result.qualification)
        } else {
          res.result.qualification = []
        }
        setState({
          ...state,
          ...res.result,
          city_name: res.result.bank_province_name + '-' + res.result.bank_city_name,
          area_name: res.result.province_name + '-' + res.result.city_name + '-' + res.result.area_name,
          cate_name: res.result.category_name.split('、').join('-'),
          shop_banner: res.result.shop_banner ? res.result.shop_banner : []
        })
      }
    })
  }
  let submit = () => {
    try {
      if (state.speed == 1) {
        if (state.type == 2 && !state.company_name) throw '请输入企业名称'
        if (!state.name) throw state.type == 1 ? '请输入个体姓名' : '请输入法人姓名'
        if (!state.id_number) throw state.type == 1 ? '请输入身份证号码' : '请输入法人身份证号码'
        if (!state.mobile) throw '请输入联系方式'
        if (!state.email) throw '请输入邮箱'
        if (!state.bank_name) throw '请输入开户名称'
        if (!state.bank_card) throw '请输入银行卡账号'
        if (!state.bank_info) throw '请输入开户银行名称'
        if (!state.bank_province_id) throw '请选择联系所在支行所在省份及市'
        if (!state.identity_card_front) throw '请上传身份证人像面'
        if (!state.identity_card_back) throw '请上传身份证国徽面'
        if (!state.contract_picture.length) throw '请上传签合同找哦片'
        if (!state.business_license) throw '请上传营业执照照片'
        console.log(state)
        setState({
          ...state,
          speed: 2
        })
      }
      if (state.speed == 2) {
        if (!state.shop_name) throw '请输入店铺名称'
        if (!state.shop_logo) throw '请输入店铺logo'
        if (!state.province_id) throw '请选择所属区域'
        if (!state.shop_address) throw '请输入店铺地址'
        if (!state.category_id) throw '请选择经营类目'
        let data = {
          type: state.type,
          name: state.name,
          id_number: state.id_number,
          mobile: state.mobile,
          email: state.email,
          bank_name: state.bank_name,
          bank_card: state.bank_card,
          bank_info: state.bank_info,
          bank_province_id: state.bank_province_id,
          bank_city_id: state.bank_city_id,
          identity_card_front: state.identity_card_front,
          identity_card_back: state.identity_card_back,
          business_license: state.business_license,
          contract_picture: state.contract_picture,
          shop_name: state.shop_name,
          shop_Introduction: state.shop_Introduction,
          shop_banner: state.shop_banner,
          shop_logo: state.shop_logo,
          province_id: state.province_id,
          city_id: state.city_id,
          area_id: state.area_id,
          shop_address: state.shop_address,
          category_id: state.category_id,
          qualification: state.qualification,
          bank_type: state.bank_type,
          is_new_version: state.is_new_version
        }
        if (state.type == 2) data.company_name = state.company_name
        axios.post('/JuTwitter/ChannelsCompany/submit', data).then((res) => {
          if (res.code == 0) {
            navigate('/enterpriseSettlementInfo', {
              replace: true
            })
          } else {
            Toast.show({
              content: res.msg
            })
          }
        })
      }
    } catch (e) {
      Toast.show({
        content: e
      })
    }
  }
  useEffect(() => {
    businessCate()
    if (location.state == 3) getInfo()
  }, [])
  return (
    <div className="settlement-apply">
      <div className="speed">
        <div>
          <span className={`${state.speed >= 1 ? 'speed-active1' : ''}`}>1</span>
          <span></span>
          <span className={`${state.speed > 1 ? 'speed-active1' : ''}`}>2</span>
          <span></span>
          <span>3</span>
        </div>
        <div>
          <span className={`${state.speed >= 1 ? 'speed-active2' : ''}`}>{state.type == 1 ? '个体工商户信息' : '企业主体信息'} </span>
          <span className={`${state.speed > 1 ? 'speed-active2' : ''}`}>店铺信息</span>
          <span>审核中</span>
        </div>
      </div>
      {state.speed == 1 ? (
        <div className="apply-form">
          <div className="personal-info">
            {state.type == 2 ? (
              <div className="form-item">
                <div className="title">企业名称</div>
                <Input value={state.company_name} onChange={(e) => setState({ ...state, company_name: e })} placeholder="请输入企业名称"></Input>
              </div>
            ) : null}
            <div className="form-item">
              <div className="title">{state.type == 1 ? '个体姓名' : '法人姓名'}</div>
              <Input value={state.name} onChange={(e) => setState({ ...state, name: e })} placeholder={state.type == 1 ? '请输入个体姓名' : '请输入法人姓名'}></Input>
            </div>
            <div className="form-item">
              <div className="title">{state.type == 1 ? '身份证号码' : '法人身份证号码'}</div>
              <Input value={state.id_number} onChange={(e) => setState({ ...state, id_number: e })} placeholder="请输入身份证号码"></Input>
            </div>
            <div className="form-item">
              <div className="title">联系方式</div>
              <Input value={state.mobile} onChange={(e) => setState({ ...state, mobile: e })} placeholder="请输入联系方式"></Input>
            </div>
            <div className="form-item">
              <div className="title">邮箱</div>
              <Input value={state.email} onChange={(e) => setState({ ...state, email: e })} placeholder="请输入邮箱"></Input>
            </div>
          </div>
          <div className="bank-type">
            <div className="left">银行账户类型</div>
            <div className="right">
              <div>
                {state.bank_type == 1 ? (
                  <p className="circle2">
                    <span></span>
                  </p>
                ) : (
                  <span className="circle1" onClick={() => setState({ ...state, bank_type: 1 })}></span>
                )}
                对公账户
              </div>
              {state.type == 1 ? (
                <div>
                  {state.bank_type == 2 ? (
                    <p className="circle2">
                      <span></span>
                    </p>
                  ) : (
                    <span className="circle1" onClick={() => setState({ ...state, bank_type: 2 })}></span>
                  )}
                  对私账户
                </div>
              ) : null}
            </div>
          </div>
          <div className="bank-info">
            <div className="form-item">
              <div className="title">
                开户名称<span>（必须与银行卡开户名称一致）</span>
              </div>
              <Input value={state.bank_name} onChange={(e) => setState({ ...state, bank_name: e })} placeholder="请输入开户名称"></Input>
            </div>
            <div className="form-item">
              <div className="title">
                银行卡账号<span>（不支持信用卡）</span>
              </div>
              <Input value={state.bank_card} onChange={(e) => setState({ ...state, bank_card: e })} placeholder="请输入银行卡账号"></Input>
            </div>
            <div className="form-item">
              <div className="title">
                开户银行<span>（开户支行需要详细写到对应支行）</span>
              </div>
              <Input value={state.bank_info} onChange={(e) => setState({ ...state, bank_info: e })} placeholder="请输入开户银行名称"></Input>
            </div>
            <div className="form-item">
              <div className="title">开户省市地区</div>
              <div className="address" onClick={() => setVisible(true)}>
                {state.city_name ? <span>{state.city_name}</span> : <Input placeholder="请选择联系所在支行所在省份及市" disabled />}
                <RightOutline fontSize={14} color="#ccc" />
                <City
                  visible={visible}
                  cancel={setVisible}
                  confirm={({ ids, name }) => {
                    setVisible(false)
                    setState({
                      ...state,
                      bank_province_id: ids[0],
                      bank_city_id: ids[1],
                      city_name: name
                    })
                  }}
                  type={1}
                ></City>
              </div>
            </div>
            <div className="form-item certificates">
              <div className="title">
                {state.type == 1 ? '身份证正反面' : '法人身份证正反面'}
                <span>（照片务必清晰）</span>
              </div>
              <div className="card">
                <div className="card-front">
                  {state.identity_card_front ? (
                    <div className="card-front-img">
                      <img src={state.identity_card_front} />
                      <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/enterprise/img5.png" onClick={() => setState({ ...state, identity_card_front: '' })} />
                    </div>
                  ) : (
                    <div className="add">
                      <img className="add" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/enterprise/img3.png" />
                      <input type="file" accept="image/*" onChange={(e) => getFiles(e, 1)} />
                    </div>
                  )}

                  <p>身份证人像面</p>
                </div>
                <div className="card-back">
                  {state.identity_card_back ? (
                    <div className="card-back-img">
                      <img src={state.identity_card_back} />
                      <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/enterprise/img5.png" onClick={() => setState({ ...state, identity_card_back: '' })} />
                    </div>
                  ) : (
                    <div className="add">
                      <img className="add" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/enterprise/img4.png" />
                      <input type="file" accept="image/*" onChange={(e) => getFiles(e, 2)} />
                    </div>
                  )}
                  <p>身份证国徽面</p>
                </div>
              </div>
            </div>
            <div className="form-item contract">
              <div className="title">
                上传签合同照片<span>（选填，照片务必清晰）</span>
              </div>
              <div className="contract-list">
                {state.contract_picture.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      <img className="contract-img" src={item} />
                      <img
                        className="remove"
                        src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/enterprise/img5.png"
                        onClick={() => {
                          let _state = _.cloneDeep(state)
                          _state.contract_picture.splice(index, 1)
                          setState(_state)
                        }}
                      />
                    </div>
                  )
                })}
                <div className="item add">
                  <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/enterprise/img6.png" />
                  <p>添加</p>
                  <input type="file" multiple accept="image/*" onChange={(e) => getFiles(e, 3)} />
                </div>
              </div>
            </div>
            <div className="form-item business-license">
              <div className="title">
                上传营业执照照片<span>（照片务必清晰）</span>
              </div>
              {state.business_license ? (
                <div className="business-license-img">
                  <img src={state.business_license} alt="" />
                  <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/enterprise/img5.png" onClick={() => setState({ ...state, business_license: '' })} />
                </div>
              ) : (
                <div className="add">
                  <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/enterprise/img6.png" />
                  <p>添加</p>
                  <input type="file" multiple accept="image/*" onChange={(e) => getFiles(e, 4)} />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="apply-form">
          <div className="shop-infos">
            <div className="form-item">
              <div className="title">店铺名称</div>
              <Input value={state.shop_name} onChange={(e) => setState({ ...state, shop_name: e })} placeholder="请输入店铺名称"></Input>
            </div>
            <div className="form-item">
              <div className="title">店铺简介</div>
              <TextArea value={state.shop_Introduction} rows={1} onChange={(e) => setState({ ...state, shop_Introduction: e })} autoSize placeholder="请输入店铺简介"></TextArea>
            </div>
            <div className="form-item logo">
              <div className="title">
                店铺logo<span>（只可上传一张）</span>
              </div>
              <div className="addLogo">
                <div className="demonstration">
                  <Image src="https://staticfile.cnweisou.net/Upload/images/20220928/38b1bdeefbffd0e6d0c7476c5639c31f-未标题-1.png" />
                  <div>店铺logo上传示范</div>
                </div>
                <div className="upLogo">
                  {state.shop_logo ? (
                    <div className="logo">
                      <Image src={state.shop_logo} />
                      <div className="close" onClick={() => setState({ ...state, shop_logo: '' })}>
                        <CloseCircleFill color="#ff5a01" fontSize={20} />
                      </div>
                    </div>
                  ) : (
                    <div className="add">
                      <Image src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/enterprise/img6.png" />
                      <div>添加</div>
                      <input type="file" accept="image/*" onChange={(e) => getFiles(e, 5)} />
                    </div>
                  )}
                  <div className="tips">建议尺寸：300*300</div>
                </div>
              </div>
            </div>
            <div className="form-item banner">
              <div className="title">店铺banner</div>
              <div className="contract-list">
                {state.shop_banner.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      <img className="contract-img" src={item} />
                      <img
                        className="remove"
                        src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/enterprise/img5.png"
                        onClick={() => {
                          let _state = _.cloneDeep(state)
                          _state.shop_banner.splice(index, 1)
                          setState(_state)
                        }}
                      />
                    </div>
                  )
                })}
                <div className="item add">
                  <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/enterprise/img6.png" />
                  <p>添加</p>
                  <input type="file" multiple accept="image/*" onChange={(e) => getFiles(e, 7)} />
                </div>
              </div>
            </div>
            <div className="form-item">
              <div className="title">所属区域</div>
              <div className="address" onClick={() => setVisible1(true)}>
                {state.area_name ? <span>{state.area_name}</span> : <Input placeholder="请选择省市区" disabled />}
                <RightOutline fontSize={14} color="#ccc" />
                <City
                  visible={visible1}
                  cancel={setVisible1}
                  confirm={({ ids, name }) => {
                    setVisible1(false)
                    setState({
                      ...state,
                      province_id: ids[0],
                      city_id: ids[1],
                      area_id: ids[2],
                      area_name: name
                    })
                  }}
                  type={2}
                ></City>
              </div>
            </div>
            <div className="form-item">
              <div className="title">店铺地址</div>
              <Input value={state.shop_address} onChange={(e) => setState({ ...state, shop_address: e })} placeholder="请输入店铺地址"></Input>
            </div>
            <div className="flex_row">
              <div className="title">点餐系统是否支持餐位+打包费</div>
              <Radio.Group defaultValue={state.is_new_version}>
                <Space>
                  <Radio
                    value={1}
                    style={{
                      '--icon-size': '18px',
                      '--font-size': '14px',
                      '--gap': '6px'
                    }}
                  >
                    是
                  </Radio>
                  <Radio
                    value={0}
                    style={{
                      '--icon-size': '18px',
                      '--font-size': '14px',
                      '--gap': '6px'
                    }}
                  >
                    否
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
            <div className="tips">（此系统选择“是”并确认提交后不可修改，请谨慎选择!）</div>
          </div>
          <div className="category">
            经营类目资质
            <span>（将向用户展示该资质材料）</span>
          </div>
          <div className="category-qualifications">
            <div className="form-item">
              <div className="title">经营类目</div>
              <div
                className="address"
                onClick={() => {
                  setVisible2(true)
                }}
              >
                {state.cate_name ? <span>{state.cate_name}</span> : <Input placeholder="请选择经营类目" disabled />}
                <RightOutline fontSize={14} color="#ccc" />
                <Cascader
                  options={cate}
                  visible={visible2}
                  onCancel={() => setVisible2(false)}
                  value={cateVal}
                  onConfirm={(value, extend) => {
                    if (value.length) {
                      let category_id = ''
                      for (let i in extend.items) {
                        category_id += extend.items[i].id + ','
                      }
                      setCateVal(value)
                      setVisible2(false)
                      setState({
                        ...state,
                        category_id: category_id.substring(0, category_id.length - 1),
                        cate_name: value.join('-')
                      })
                    }
                  }}
                ></Cascader>
              </div>
            </div>
            <div className="form-item qualification">
              <div className="title">
                上传相关资质证件<span>（餐饮业必填，其他选填）</span>
              </div>
              <div className="qualification-list">
                {state.qualification.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      <img className="qualification-img" src={item} />
                      <img
                        className="remove"
                        src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/enterprise/img5.png"
                        onClick={() => {
                          let _state = _.cloneDeep(state)
                          _state.qualification.splice(index, 1)
                          setState(_state)
                        }}
                      />
                    </div>
                  )
                })}
                <div className="item add">
                  <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/enterprise/img6.png" />
                  <p>添加</p>
                  <input type="file" multiple accept="image/*" onChange={(e) => getFiles(e, 6)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="btn">
        {state.speed == 2 ? (
          <div className="top" onClick={() => setState({ ...state, speed: 1 })}>
            上一步
          </div>
        ) : null}
        <div className="bot" onClick={() => submit()}>
          {state.speed == 1 ? '下一步' : '确认提交'}{' '}
        </div>
      </div>
    </div>
  )
}

export default EnterpriseSettlementApply
